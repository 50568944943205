export const constant = {
  FILE_UPLOAD: 'basics/files/upload',
  CREATE_FACILITY: 'facilities/',
  LOGIN: 'auth/token/obtain/',
  PENDING_APPOINTMENTS_FACILITY: 'patients/appointments/facility/pending',
  SUBMITTED_APPOINTMENTS_FACILITY: 'patients/appointments/facility/submitted',
  APPOINTMENTS_FACILITY: 'patients/appointments/facility',
  GET_USER_LIST_BY_FACILITY: 'users/facility/',
  PHYSIO_DASHBAORD_STATISTICS: '',
  PHYSIO_WORK_ORDERS: 'patients/physio-work-order',
  PHYSIO_START_CONSULTANT: 'patients/start-consult',
  PHYSIO_SAVE_CONSULTANT: 'patients/save-consult',
  PATIENT_HISTORY: 'patients/patient-history',
  DOCTOR_360_FETCH_MEASURMENT: 'patients/measurements-list',
  DOCTOR_360_FETCH_FORMULATION: 'patients/formulations-list',
  DOCTOR_360_FETCH_DRUG: 'patients/drug-list',
  PHYSIO_USER_ASSIGNED: '',
  PHYSIO_USER_MESSAGES: '',
  CHECK_USER_AUTHENTICATION: 'auth/login-details',
  PHYSIO_USER_DETAILS: 'patients/physio-user-details',
  RADIOLOGY_AND_LABORATORY_MASTER_DATA: 'patients/radiolab-list',
  PATIENT_DETAILS: 'patients/v1/details',
};

