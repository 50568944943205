import React, { Suspense, lazy } from 'react';
import { HashRouter, Router, Route, Switch } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { history } from '../store';
import { PublicRoute } from './publicRoute';
import { PrivateRoute } from './privateRoute';
import { LaboratoryRoute } from './laboratoryRoute';
import { PhysioRoute } from './physioRoute';
import { PhysioUserRoute } from './physioUserRoute';
import NotFound from '../components/not-found';
import LogIn from '../components/login';
import PasswordReset from '../components/password_reset';
import password_reset from '../components/password_reset';


const LaboratoryLayout = lazy(() =>
  import('../components/Laboratory/theme/Layout'),
);
const PhysioLayout = lazy(() =>
  import('../components/Physio/theme/Layout'),
);
const PhysioUserLayout = lazy(() =>
  import('../components/Physio-User/theme/Layout'),
);

const DoctorLayout = lazy(() =>
  import('../components/Doctor/theme/Layout'),
);

const DoctorUserLayout = lazy(() =>
  import('../components/Doctor-User/theme/Layout'),
);


const AdminLayout = lazy(() =>
  import('../components/Admin/theme/Layout'),
);



const Registration = lazy(() =>
  import('../components/Registration/registration'),
);
const RegistrationFacility = lazy(() =>
  import('../components/Registration/facility'),
);
const RegistrationAddress = lazy(() =>
  import('../components/Registration/address'),
);
const RegistrationSchedule = lazy(() =>
  import('../components/Registration/schedule'),
);
const RegistrationSummary = lazy(() =>
  import('../components/Registration/summary'),
);
const RegistrationComplete = lazy(() =>
  import('../components/Registration/complete'),
);

const routes = (
  <HashRouter basename="/">
    <Suspense fallback={<LinearProgress />}>
      <Switch>
        <Route path="/" component={LogIn} exact />
        <Route path="/password-reset/:id" component={password_reset} />

        <PublicRoute path="/registration" component={Registration} />
        <PublicRoute
          path="/registration-facility"
          component={RegistrationFacility}
        />
        <PublicRoute
          path="/registration-address"
          component={RegistrationAddress}
        />
        <PublicRoute
          path="/registration-schedule"
          component={RegistrationSchedule}
        />
        <PublicRoute
          path="/registration-summary"
          component={RegistrationSummary}
        />
        <PublicRoute
          path="/registration-complete"
          component={RegistrationComplete}
        />

        <Route path="/lab" component={LaboratoryLayout} />

        <Route path="/physio" component={PhysioLayout} />    
        <Route path="/physio-user" component={PhysioUserLayout} />    
        <Route path="/doctor" component={DoctorLayout} /> 
        <Route path="/doctor-user" component={DoctorUserLayout} /> 
        <Route path="/admin" component={AdminLayout} />    

        <Route path="*" component={NotFound} />
      </Switch>
    </Suspense>
  </HashRouter>
);

const RouteComponents = () => <Router history={history}>{routes}</Router>;

export default RouteComponents;
