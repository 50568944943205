import React from 'react';
import {makeStyles,Box,Link} from '@material-ui/core';

const primary='#0D1424'
const white='#fff'
const headerStyle = makeStyles({
  '@global': {
    header:{width: '100%',height:'4.375rem',padding:'0 0.5rem 0 2.5rem',backgroundColor:primary,boxShadow:'rgba(0,0,0,0.16) 0 0.1875rem 1.25rem',position:'fixed',zIndex: 10, display: 'flex',alignItems: 'center',
      '& .brand':{maxWidth:'20rem',height:'100%', display:'flex',alignItems: 'center',justifyContent: 'center',
        '& a':{width:'100%',height:'100%',display:'flex',
          '&  img':{width:'100%',height:'auto',},
        },
      },
      '& .topBtn':{display: 'flex',alignItems: 'center',marginLeft: 'auto',},
      '& button':{
        '&.MuiButton-root,&.MuiIconButton-root':{color: white,},
      },
    },
  },
});
export default function Header(){
  const classes = headerStyle();
  return (
    <>
      <Box component="header" className={classes.header}>
        <Box className="brand">
          <Link href="/#/">
            <img src="php-logo.png" alt=""/>
          </Link>
        </Box>      
      </Box>
    </>    
  )

};
