import React, { useState, useRef } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { makeStyles, Link } from '@material-ui/core';
import Nav2 from './Nav2';
import NavDoctor from './NavDoctor';
import NavDoctorUser from './NavDoctorUser';
import NavLaboratory from './NavLaboratory';
import NavPhysio from './NavPhysio';
import NavPhysioUser from './NavPhysioUser';
import './Theme.scss';

import {
    Build,
    Home,
    ImportContacts,
    ShoppingCart,
    Accessibility,
    Person,
    Security,
    Domain,
    LocalOffer,
    Receipt,
    PeopleAlt,
    Layers,
    Settings,
} from '@material-ui/icons';
const primary = '#0D1424';
const secondary = '#F0625C';
const doctorSecondary = '#69cbe1';
const white = '#fff';
const gray = '#ccc';
const bgc = '#F9F5F5';
const navStyle = makeStyles({
    menu: {
        position: ' fixed',
        zIndex: 8,
        // width: '19.6rem',
        height: ' 100%',
        backgroundColor: bgc,
        left: 0,
        top: 0,
        paddingTop: '9rem',
        borderRight: `${gray} solid 0.0625rem`,
        fontFamily: 'helveticaneue',
        fontWeight: 300,
        fontSize: ' 1.125rem',
        overflowX: 'hidden',
        overflowY: 'auto',
        WebkitTransition: ' all .3s',
        transition: ' all .3s',
        '& ul.left-nav': {
            position: ' relative',
            padding: 0,
            margin: 0,
            listStyle: 'none',
            '& li': {
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                '& a': {
                    height: '  ',
                    whiteSpace: 'nowrap',
                    borderBottom: `${gray} solid 0.0625rem`,
                    padding: ' 0.75rem 1.5rem',
                    display: 'flex',
                    alignItems: ' center',
                    color: '#323F4A',
                    position: ' relative',
                    '& svg, & i': {
                        width: '1.25rem',
                        height: '1.25rem',
                        fontSize: '1.25rem',
                        color: secondary,
                        marginRight: '1.875rem',
                    },
                    '&::after': {
                        content: `''`,
                        position: 'absolute',
                        borderColor: ' transparent',
                        borderTopColor: secondary,
                        borderWidth: '0.375rem',
                        borderStyle: 'solid',
                        right: '1.5rem',
                        top: 'calc(50% - 0.1875rem)',
                        display: 'none',
                    },
                    '&.active': { fontWeight: 500, color: ' #323F4A' },
                },
                '&:hover,&.active': {
                    '&>a': { fontWeight: 500, color: ' #323F4A' },
                    '&>ul': { maxHeight: '100rem', overflow: ' visible' },
                },
                '& ul': {
                    maxHeight: ' 00rem',
                    position: 'relative',
                    padding: 0,
                    margin: 0,
                    listStyle: ' none',
                    fontWeight: 300,
                    backgroundColor: ' #E7E9EB',
                    order: 1,
                    WebkitTransition: ' all .3s',
                    transition: ' all .3s',
                    overflow: ' hidden',
                    fontSize: ' 1rem',
                    '& + a:after': { display: ' block' },
                    '& li': {
                        '& a': {
                            padding: ' 0.75rem 1.5rem 0.75rem 4.625rem',
                            WebkitTransition: ' all .3s',
                            transition: ' all .3s',
                        },
                        '&:hover,&.active': {
                            '&>a': {
                                backgroundColor: secondary,
                                color: white,
                                '&:after': { borderTopColor: white },
                            },
                            '&>ul': { maxHeight: ' 100rem' },
                        },
                    },
                    '& ul': {
                        backgroundColor: ' #DDF0FA',
                        position: ' absolute',
                        zIndex: 5,
                        width: ' 100%',
                        top: ' 2.875rem',
                        '& li': {
                            '&:hover,&.active ': {
                                '&>a': { backgroundColor: primary },
                            },
                        },
                    },
                },
            },
        },
    },
    '@media only screen and (max-width: 1350px)': {
        menu: {
            '&:not(:hover)': {
                width: '3rem',
                '& ul.left-nav': {
                    '& li': {
                        '& a': {
                            justifyContent: 'center',
                            fontSize: 0,
                            '& svg, & i': { marginRight: 0 },
                        },
                        '& ul+ a:after': { display: ' none' },
                    },
                },
            },
        },
    },
    '@media only screen and (max-width: 850px)': {
        menu: {
            width: '15.5rem',
            '&:not(:hover)': {
                width: '4rem',
                '& ul.left-nav': {
                    '& li': {
                        '& a': {
                            justifyContent: 'center',
                            fontSize: 0,
                            '& svg, & i': { marginRight: 0 },
                        },
                        '& ul+ a:after': { display: ' none' },
                    },
                },
            },
        },
    },
});

const doctorNavStyle = makeStyles({
    menu: {
      position: ' fixed',
      zIndex: 8,
      // width: '15.5rem',
      height: ' 100%',
      backgroundColor: bgc,
      left: 0,
      top: 0,
      paddingTop: '9rem',
      borderRight: `${gray} solid 0.0625rem`,
      fontFamily: 'helveticaneue',
      fontWeight: 300,
      fontSize: ' 1.125rem',
      overflowX: 'hidden',
      overflowY: 'auto',
      WebkitTransition: ' all .3s',
      transition: ' all .3s',
      '& ul.left-nav': {
        position: ' relative',
        padding: 0,
        margin: 0,
        listStyle: 'none',
        '& li': {
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          '& a': {
            height: '  ',
            whiteSpace: 'nowrap',
            borderBottom: `${gray} solid 0.0625rem`,
            padding: ' 0.75rem 1.5rem',
            display: 'flex',
            alignItems: ' center',
            color: '#323F4A',
            position: ' relative',
            '& svg, & i': {
              width: '1.25rem',
              height: '1.25rem',
              fontSize: '1.25rem',
              color: secondary,
              marginRight: '1.875rem',
            },
            '&::after': {
              content: `''`,
              position: 'absolute',
              borderColor: ' transparent',
              borderTopColor: secondary,
              borderWidth: '0.375rem',
              borderStyle: 'solid',
              right: '1.5rem',
              top: 'calc(50% - 0.1875rem)',
              display: 'none',
            },
            '&.active': { fontWeight: 500, color: ' #323F4A' },
          },
          '&:hover,&.active': {
            '&>a': { fontWeight: 500, color: ' #323F4A' },
            '&>ul': { maxHeight: '100rem', overflow: ' visible' },
          },
          '& ul': {
            maxHeight: ' 00rem',
            position: 'relative',
            padding: 0,
            margin: 0,
            listStyle: ' none',
            fontWeight: 300,
            backgroundColor: ' #E7E9EB',
            order: 1,
            WebkitTransition: ' all .3s',
            transition: ' all .3s',
            overflow: ' hidden',
            fontSize: ' 1rem',
            '& + a:after': { display: ' block' },
            '& li': {
              '& a': {
                padding: ' 0.75rem 1.5rem 0.75rem 4.625rem',
                WebkitTransition: ' all .3s',
                transition: ' all .3s',
              },
              '&:hover,&.active': {
                '&>a': {
                  backgroundColor: secondary,
                  color: white,
                  '&:after': { borderTopColor: white },
                },
                '&>ul': { maxHeight: ' 100rem' },
              },
            },
            '& ul': {
              backgroundColor: ' #DDF0FA',
              position: ' absolute',
              zIndex: 5,
              width: ' 100%',
              top: ' 2.875rem',
              '& li': {
                '&:hover,&.active ': {
                  '&>a': { backgroundColor: primary },
                },
              },
            },
          },
        },
      },
    },
    '@media only screen and (max-width: 1350px)': {
      menu: {
        '&:not(:hover)': {
          width: '3rem',
          '& ul.left-nav': {
            '& li': {
              '& a': {
                justifyContent: 'center',
                fontSize: 0,
                '& svg, & i': { marginRight: 0 },
              },
              '& ul+ a:after': { display: ' none' },
            },
          },
        },
      },
    },
  
    '@media only screen and (max-width: 850px)': {
      menu: {
        width: '15.5rem',
        '&:not(:hover)': {
          width: '4rem',
          '& ul.left-nav': {
            '& li': {
              '& a': {
                justifyContent: 'center',
                fontSize: 0,
                '& svg, & i': { marginRight: 0 },
              },
              '& ul+ a:after': { display: ' none' },
            },
          },
        },
      },
    },
  });

export default function Nav(props) {
    const history = useHistory();
    const pathname = (history.location && history.location.pathname) ? history.location.pathname.split('/') : '';
    const navClasses = navStyle();
    const doctorClasses = doctorNavStyle();

    if(props && props.role === 'supervisor'){
        pathname[1] = 'physio';
    }else if(props && props.role === 'user'){
        pathname[1] = 'physio-user';
    }
    
    switch (pathname[1]) {
        case 'admin':
            return (
                <>
                    <nav className={navClasses.menu}>
                        <Nav2 />
                    </nav>
                </>
            );
        case 'doctor':
            const ref1 = useRef();
            const [menuResponsive, setmenuResponsive] = useState(false);
            const updateMenu = () => {
                if (ref1.current.offsetWidth == 344) {
                    setmenuResponsive(true);
                } else {
                    setmenuResponsive(false);
                }
            };
            return (
                <>
                    <nav
                        ref={ref1}
                        className={
                            menuResponsive ? doctorClasses.menu + ' menu_responsive' : doctorClasses.menu
                        }>
                        <NavDoctor updateMenu={updateMenu} />
                    </nav>
                </>
            );
        case 'doctor-user':
            return (
                <>
                    <nav className={doctorClasses.menu}>
                        <NavDoctorUser />
                    </nav>
                </>
            );
        case 'lab':
            return (
                <>
                    <nav className={navClasses.menu}>
                        <NavLaboratory />
                    </nav>
                </>
            );
        case 'physio':
            return (
                <>
                    <nav className={doctorClasses.menu}>
                        <NavPhysio />
                    </nav>
                </>
            );
        case 'physio-user':
            return (
                <>
                    <nav className={doctorClasses.menu}>
                        <NavPhysioUser />
                    </nav>
                </>
            );
        default:
            return null;
    }
}
