import React from 'react';
import RouteComponents from './routes';

const App = () => {
  return (
    <>
      <div>
        <RouteComponents />
      </div>
    </>
  );
};

export default App;
