export const initializeRegistrationData = () => {
  const admin_object = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  };

  const facility_object = {
    name: '',
    parent_branch: '',
    short_name: '',
    facility_type: '',
    keph_level: '',
    kmfl_code: '',
    lab: '',
    logo: [],
  };

  const address_object = {
    address_line1: '',
    address_line2: '',
    location: '',
    country: '',
    county: '',
    subcounty: '',
    ward: '',
    website: '',
    email: '',
    phone_number: '',
    contactPersons: [
      { id: 0, name: '', designation: '', contact_person_email: '', phone: '' },
    ],
  };

  localStorage.setItem('admin_object', JSON.stringify(admin_object));
  localStorage.setItem('facility_object', JSON.stringify(facility_object));
  localStorage.setItem('address_object', JSON.stringify(address_object));
};

export const clearRegistrationData = () => {
  localStorage.removeItem('admin_object');
  localStorage.removeItem('facility_object');
  localStorage.removeItem('address_object');
  localStorage.removeItem('schedule_object');
  localStorage.removeItem('holiday_object');
  localStorage.removeItem('contact_people_object');
  localStorage.removeItem('logo');
};

export const signOut = () => {
//   console.log('-------signing out');
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('user_email');
  localStorage.removeItem('user_id');
  localStorage.removeItem('user_profile');
  localStorage.removeItem('logo');
  // window.location.href = '/#/';
};

export const store_logo = async (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    localStorage.setItem('logo', reader.result);
  };
};

export const get_logo = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
