import React from 'react';
import Header from './Header';
import Footer from './../../../UiComponents/theme/Footer';
import '../../../assets/css/layout.scss';

export default function Layout(props){
  return (
    <>
      <Header history={props.history} />
      <div className="registration-contain">
      	{props.children}
      </div>
      <Footer/>
    </>
  )
};


