import React, { Suspense, lazy }  from 'react';
import Header from './Header';
import { HashRouter, Router, Route, Switch } from 'react-router-dom';
import Nav from './../../../UiComponents/theme/Nav';
import '../../../assets/css/layout.scss';
import {createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
const LabHome = lazy(() => import('../dashboard'));
const PendingAppointments = lazy(() => import('../pending-appointments'));
const SubmittedAppointments = lazy(() => import('../submitted-appointments'));
const Vaccinations = lazy(() => import("../vaccinations"))
const WorkOrders = lazy(() => import('../work-orders'));
import {AuthApp} from '../../../checkAuthenticationHoc';
const theme = createTheme({
  palette: {
    primary: {
      light: '#333a4c',
      main: '#0d1424',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f3817c',
      main: '#F0625C',
      dark: '#a84440',
      contrastText: '#fff',
    },
    white: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#fff',
    },
    error: {
      light: '#ff7663',
      main: '#FF543D',
      dark: '#b23a2a',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: "'galanogrotesque', sans-serif",
    fontSize: 16,
    fontStyle: '400',
    fontDisplay: 'block',
  },
  tooltip: {
    backgroundColor: '#333',
    color: '#fff',
    fontSize: 11,
  },
});
const Layout = (props) => {
  return (
    <>
    <MuiThemeProvider theme={theme}>  
      <Header history={props.history} />
      <Nav />
      <div className="contain-part">
      <Suspense fallback={<LinearProgress />}>
        <Switch>        
        <Route
			path={`/lab/work-orders`}
			component={WorkOrders}
        />
          <Route
			path={`/lab/pending-appointments`}
			component={PendingAppointments}
        />
        <Route
			path="/lab/submitted-appointments"
			component={SubmittedAppointments}
        />
        <Route 
			path="/lab/dashboard" 
			component={LabHome} 
		/>   
        </Switch>
		<Route
			path="/lab/vaccinations"
			component={Vaccinations}
        />
        </Suspense>    
        </div>
       
      {/* <Footer /> */}
      </MuiThemeProvider>
    </>
  );
};
export default AuthApp(Layout, 'Laboratory');
