import React, { lazy, Suspense } from 'react';
import Header from './../../../UiComponents/Header';
import Nav from './../../../UiComponents/theme/Nav';
import { HashRouter, Router, Route, Switch } from 'react-router-dom';
import {
	createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import '../../../assets/css/layout.scss';
import LinearProgress from '@material-ui/core/LinearProgress';
import { AuthApp } from '../../../checkAuthenticationHoc';

const PhysioHome = lazy(() => import('../dashboard'));
const PhysioWorkOrders = lazy(() => import('../physioworkorders'));

const PhysioWorkList = lazy(() => import('../physioworklist'));
const PhysioPatientDetails = lazy(() => import('../../Doctor-User/DoctorUserDetails'));
const PhysioUserAssigned = lazy(() =>
  import('../../../UiComponents/UserAssigned'),
);
const PhysioUserWorkOrders = lazy(() =>
  import('../../Physio-User/PhysioUserWorkorder'),
);

const theme = createTheme({
  palette: {
    primary: {
      light: '#525151',
      main: '#767474',
      dark: '#918f8f',
      contrastText: '#fff',
    },
    secondary: {
      light: '#9efeff',
      main: '#69CBE1',
      dark: '#2e9aaf',
      contrastText: '#000',
    },
    white: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#000',
    },
    error: {
      light: '#ff7663',
      main: '#FF543D',
      dark: '#b23a2a',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: "'galanogrotesque', sans-serif",
    fontSize: 16,
    fontStyle: '400',
    fontDisplay: 'block',
  },
  tooltip: {
    backgroundColor: '#333',
    color: '#fff',
    fontSize: 11,
  },
});
const Layout = (props) => {
  return (
    <>
      <MuiThemeProvider theme={theme}>
        <Header history={props.history} />
        <Nav />

        <div className="contain-part">
          {/* <Footer/> */}
          <Suspense fallback={<LinearProgress />}>
            <Switch>
              <Route path="/physio/dashboard" component={PhysioHome} />
              <Route path="/physio/work-orders" component={PhysioWorkOrders} />
              <Route path="/physio/work-list" component={PhysioWorkList} />
              <Route
                path="/physio/patient-details/:id"
                component={PhysioPatientDetails}
              />
              <Route
                path="/physio/user-work-orders"
                component={PhysioUserWorkOrders}
              />

              <Route
                path="/physio/user-assigned"
                component={PhysioUserAssigned}
              />
            </Switch>
          </Suspense>
        </div>
      </MuiThemeProvider>
    </>
  );
};

export default AuthApp(Layout, 'Physio');
