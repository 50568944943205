import React, { useState } from 'react'
import { connect } from 'react-redux'

import { Field, reduxForm } from 'redux-form'
import { required } from 'redux-form-validators'
import { Box, Button, Fab, MenuItem, Zoom, Snackbar, Tooltip, Typography } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { useParams } from 'react-router-dom';
import { InfoOutlined } from "@material-ui/icons";
import {
  renderTextInput, suffixTextInput
} from '../UiComponents/renderUtils'
import '../assets/css/login.scss'

// Own
import { reset_password } from '../utilities/api/apiCalls'


const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />

const PasswordReset = (props) => {

  const [helperText, setHelperText] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [alertType, setAlertType] = React.useState('')
  const [new_password, setNewPassword] = React.useState('')
  const [new_password_2, setNewPassword2] = React.useState('')
  const [passBlur, setPassBlur] = React.useState(false)
  const params = useParams();
  const [user_id, setUserID] = React.useState(params.id)




  const handleValidSubmit = () => {
		setTimeout(()=>{
			window.location.href = '/#/'
		} , 3000);
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
	if (new_password.length < 1){
		setAlertType("warning")
		setHelperText("You must input a password")
		return
	}
	if (new_password_2.length < 1){
		setAlertType("warning")
		setHelperText("Please confirm password")
		return
	}
	setHelperText('')
	setAlertType("success")
	setHelperText("You have successfully reset your password. Redirecting...")
	


    await reset_password(new_password, user_id)
      .then((response) => {
        if (response.status === 200) {
			setHelperText('')
			setAlertType("success")
			setHelperText("You have successfully reset your password. Redirecting...")
          	handleValidSubmit()
        } else {
			setHelperText('')
			setAlertType('error')
		  	setHelperText("Failed to reset password")
        }
      })
      .catch(error => {
		  const msg = (error.message !== undefined) ? error.message : error.Error
		  setAlertType('error')
		  setHelperText(msg)
	  })
	  
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const validPass = () => {
    if (new_password.length < 8) {
      return false;
    }
	
    let has_char = false;
    let has_upper = false;
    let has_lower = false;
    let has_num = false;
    for (let i = 0; i < new_password.length; i++) {
      const the_char = new_password[i];
      if (/[A-Z]/.test(the_char)) {
        has_char = true;
        has_upper = true;
      }
      if (/[a-z]/.test(the_char)) {
        has_char = true;
        has_lower = true;
      }
      if (the_char >= "0" && the_char <= "9") {
        has_num = true;
      }
    }
    return has_char && has_upper && has_lower && has_num;
  };

  const pass_match = () => {
    if (new_password.length > 0 && new_password_2 !== new_password) {
      return false;
    }
    return true;
  };

  const submit_disabled = () =>{

	  
	  if(!validPass() || !pass_match()){
		  return true
	  }
	  return false
  }

  return (
    <>
      <Box component="section" className="logBg">
	  <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleClose} severity={alertType}>
            {helperText}
          </Alert>
        </Snackbar>

        <Box component="div" className="log-heading">
          <h1 className="log-text">
            Welcome to
            <br /> PoneaPractice
          </h1>
          <h2 className="log-subtext">Your patients await!</h2>
        </Box>
        <div className="log-box arrive">
          <div className="log-logo">
            <img src="php-logo.png" alt="" />
          </div>
          <div className="log-body">
            <form autoComplete="off" onSubmit={handleSubmit}>
			<Field
                  onChange={(e) => {
                    setPassBlur(false);
                    setNewPassword(e.target.value);
                  }}
                  onBlur={() => {
                    setPassBlur(true);
                  }}
                  name="new_password"
                  component={suffixTextInput}
                  type="password"
                  label="New Password"
                  size="small"
                  variant="outlined"
                  suffix={
                    <Tooltip
                      title="At least 8 characters containing a capital letter, a lower case and a numeric character"
                      placement="bottom-end"
                      arrow
                      TransitionComponent={Zoom}
                    >
                      <InfoOutlined fontSize="small" />
                    </Tooltip>
                  }
                  className="form-group"
                  value={new_password}
                  required
                //   validate={[required({ message: "Field is required." })]}
                />
                {passBlur && !validPass() && (
					<div>
                  <Typography variant="caption" style={{ color: "black" }}>
                    At least 8 characters containing a capital letter, a lower
                    case letter and a numeric character.
                  </Typography>
				  <br/>
				  <br/>
				  </div>
				  
                )}

				<Field
                    onChange={(e) => {
                      setNewPassword2(e.target.value);
                    }}
                    error
                    name="new_password_2"
                    component={renderTextInput}
                    type="password"
                    label="Re Type Password"
                    size="small"
                    variant="outlined"
                    className="form-group"
                    value={new_password_2}
                    required
                    // validate={[required({ message: "Field is required." })]}
                  />
                  {(new_password_2.length > 0 && !pass_match()) && (
                    <Typography variant="caption" style={{ color: "black" }}>
                      Password mismatch
                    </Typography>
                  )}
              
              <box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
				  <br/>
				  <br/>
				  <br/>
				  <div>
					<Fab style={{width:"100%"}} disabled={submit_disabled()} variant="extended" type="submit">
						Reset Password{submit_disabled()}
					</Fab>
				  </div>
                
              </box>
            </form>
          </div>
        </div>
      </Box>
    </>
  )
}

const mapStateToProps = (state, props) => ({
    initialValues: {
      name: '',
      // country: 'admin'
    },
  })
export default connect(mapStateToProps)(
  reduxForm({ form: 'user-create-form' })(PasswordReset),
)
