import axios from 'axios';
import { constant } from './apiUtils/constants';
import * as api from './apiUtils/apiUrl';
import { store } from '../../store/index';
import { handleError, handleSuccess, objectToParam } from '../common';
import {
  conciergePUrl,
  conciergeSUrl,
  auth_url_callcenter,
  auth_body_callcenter,
  baseURL,
} from './apiUtils/apiUrl';
import { setCallcenterToken } from '../../reducers/Auth/action';
// TODO: give some authority to making a user or look into the process of creating an admin

/**
 * Upload file to Amamzon aws server
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Formdata} data
 * @return {Promise}
 */

export async function getCallcenterAuth() {
  const state = store.getState();
  return new Promise((resolve, reject) => {
    if (!state.userData.callcenter_token) {
      return axios
        .post(auth_url_callcenter, auth_body_callcenter)
        .then((res) => {
          if (res.status === 200) {
            store.dispatch(setCallcenterToken(res.data.key));
            resolve(res.data.key);
          }
        });
    }
    resolve(state.userData.callcenter_token);
  });
}

/**
 * Auth Check Api
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Object} data
 * @return {Promise}
 */

export async function checkUserAuthentication(access_token) {
  const API = `${api.baseURL}${constant.CHECK_USER_AUTHENTICATION}`;
  return new Promise((resolve, reject) =>
    axios
      .get(API, {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          handleError(response.data.message);
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
        // handleError(error.message);
      }),
  );
}
