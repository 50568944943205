import axios from 'axios';
import { toast } from 'react-toastify';

/**
 * You can set timeout for axios object
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Number} timeout
 * @return {Axios} httpClient
 */

export function getHTTPClient(timeout = 20000) {
  const httpClient = axios.create();
  httpClient.defaults.timeout = timeout;
  return httpClient;
}

/**
 * You can set timeout for axios object
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {String} x
 * @return {String} x
 */
export function numberWithCommas(x) {
  if (!isNaN(x)) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

/**
 * You can set as default avatar image
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {String} image
 * @return {String}
 */

export function defaultAvatar(image) {
  if (image === '' || image === null) {
    return 'https://portal.staralliance.com/cms/aux-pictures/prototype-images/avatar-default.png/@@images/image.png';
  }
  return image;
}

/**
 * You can parse the integer value to decimal part
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Number/String} number
 * @param {Number} decimal
 * @return {String}
 */

export function getFloat(number, decimal = 2) {
  if (
    typeof number !== 'undefined' &&
    number != null &&
    typeof number === 'number'
  ) {
    return parseFloat(number).toFixed(decimal);
  }
}

/**
 * Check the empty object
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Object} obj
 * @return {Boolean}
 */

export function isEmpty(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

/**
 * Make unique Id
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Number/String} number
 * @param {Number} decimal
 * @return {String}
 */

export function makeid(length) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/**
 * Convert the string to first letter Capitalize
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {String} string
 * @return {String}
 */

export function jsUcfirst(string) {
  if (typeof string !== 'undefined' && string != null) {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  // return string;
}

/**
 * create FormData object from javascript object
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Object} body
 * @return {FormData}
 */

export function createFormData(body) {
  const data = new FormData();
  Object.keys(body).forEach((key) => {
    data.append(key, body[key]);
  });

  return data;
}

/**
 * create FormData object from javascript object
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Object} body
 * @return {FormData}
 */

export function textShorter(text, length = 15) {
  // console.log(text)
  if (text === '' || text === null || typeof text === 'undefined') return null;
  return text.length > length
    ? `${text.toString().substr(0, length)} ...`
    : text;
}

/**
 * create FormData object from javascript object
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Object} body
 * @return {FormData}
 */

export function validPhoneNumber(value) {
  if (typeof value !== 'undefined') {
    if (value === '') {
      return false;
    }
    value = value.match(/(\d+)/g).join('');
    const pattern = new RegExp(/^[0-9\b]+$/);
    if (!pattern.test(value)) {
      return false;
    }
    return true;
  }
  return false;
}

/**
 * Validate email
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Object} body
 * @return {FormData}
 */
export function ValidateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }

  return false;
}

/**
 * Wait for a time to call the method
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Number} timeout
 * @return {FormData}
 */

export const wait = (timeout) =>
  new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });

/**
 * limit the number of times a function executes. It is used when there are DOM events that fire execution of a function
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {function} fn
 * @param {Number} delay
 * @return {FormData}
 */

export const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
    const context = this;
     timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
      // clearTimeout(timer);
    }, delay);
  };
};

/**
 * limit the number of times a function executes. It is used when there are DOM events that fire execution of a function
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {function} fn
 * @param {Number} delay
 * @return {FormData}
 */

 export const throttle = (fn, delay) => {
  let timer = null;
  return function (...args) {
    const context = this;    
    timer = setTimeout(() => {
      fn.apply(context, args);
      clearTimeout(timer);
    }, delay);
  };
};


/**
 * Return string from from Python serializers validation object
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Object} errors
 * @return {String}
 */
export function getErrors(errors) {
  if (typeof errors === 'string' || errors instanceof String) {
    return errors;
  }
  if (Array.isArray(errors)) {
    for (const item of errors) {
      return getErrors(item);
    }
  } else {
    for (const item in errors) {
      return getErrors(errors[item]);
    }
  }
}

/**
 * Toast message for error
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Object/String/Array} message
 * @return {ToastObject}
 */

export function handleError(message) {
  toast.error(getErrors(message));
}

/**
 * Toast message for success
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Object/String/Array} message
 * @return {ToastObject}
 */

export function handleSuccess(message) {
  toast.success(getErrors(message));
}

/**
 * Convert obj to url params
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Object/String/Array} obj
 * @return {String}
 */

export const objectToParam = (obj) => {
  var str = '';
  for (var key in obj) {
    if (str !== '') {
      str += '&';
    }
    str += key + '=' + encodeURIComponent(obj[key]);
  }
  return str;
};


