import {combineReducers} from "redux";
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from "redux-form";
import facilityRegistrationForm from './Registration/reducer';
import userData from './Auth/reducer'

export const createRootReducer = (history) => combineReducers({
    routing: connectRouter(history),
    facilityRegistrationForm,
    userData,
    form: formReducer  
}) 