import { ContactsOutlined } from '@material-ui/icons';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { handleError, handleSuccess } from '../common';
import { store } from '../../store/index';
import { objectToParam } from '../common';
// TODO: give some authority to making a user or look into the process of creating an admin
const baseURL = process.env.REACT_APP_API_URL;
const auth_url = process.env.REACT_APP_CONCIERGE_AUTHENTICATE_URL;
const auth_body = JSON.parse(process.env.REACT_APP_CONCIERGE_AUTH_DATA);
const conciergeSUrl = process.env.REACT_APP_CONCIERGE_SERVICE;
const conciergePUrl = process.env.REACT_APP_CONCIERGE_PACKAGES;

export const api_base = axios.create({
  baseURL,
  //   timeout: 10000,
  headers: {
    // Authorization: 'JWT ' + localStorage.getItem('access_token'),
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

export const get_facility_id = () => {
  const state = store.getState();
  const facility_id = state.userData.userData.facility;
  let uDetail = JSON.parse(localStorage.getItem('user_profile'));
  if(!facility_id){
    return uDetail.facility
  }
  return facility_id;
};

export const getExternalId = () => {
  const state = store.getState();
  const external_facility_id = state.userData.userData.external_facility_id;
  return external_facility_id;
};

export const get_user_id = () => {
  const state = store.getState();
  const user_id = state.userData.userData.user;
  return user_id;
};

export const get_local_user_profile = () => {
  const state = store.getState();
  const user_profile = state.userData.userData;
  return user_profile;
};

const handle_error = (err, msg) => {
  let error = err;
  if (error.response) {
    error = error.response;
    return error;
  }
  if (error.data) {
    error = error.data;
    return error;
  }
  if (msg) {
    console.log(msg);
  }
  // handleError(error)
  console.log(` Error(s):`, error);
  return Promise.reject(error);
};

export const login = async (credentials) => {
  const response = await axios
    .post(`${baseURL}auth/token/obtain/`, credentials)
    .then((response) => {
      if (response.status === 200) {
        localStorage.setItem('user_email', credentials.email);
        localStorage.setItem(
          'user_profile',
          JSON.stringify(response.data.user_profile),
        );
        return response;
      }
    })
    .catch((error) => {
      //  if (error.response.status == 401) {
      handleError(error.response.data);
      //  }
    });

  return response;
};

export const reset_password = async (password, id) => {
  const password_object = {
    password,
  };
  const response = await axios
    .patch(`${baseURL}auth/password_reset/${id}/`, password_object)
    .then((response) => {
      if (response.status === 200) {
        console.log('password successfully reset');
      }
      return response;
    })
    .catch(handle_error);

  return response;
};

export const request_password_reset = async (email) => {
  const obj = {
    email,
  };
  const response = await axios
    .post(`${baseURL}auth/request_password_reset/`, obj)
    .then((response) => {
      if (response.status === 200) {
        console.log('request for reset sent');
      }
      return response;
    })
    .catch(handle_error);

  return response;
};

export const register = async (user) => {
  const response = axios
    .post(`${baseURL}users/user/create/`, user)
    .then((res) => {
      if (res.status === 200) {
        localStorage.setItem('reg', true);
      }
    })
    .catch(handle_error);

  console.log(response.status);
};

export const get_user_profile = async (user_id) => {
  const response = await api_base
    .get(`users/${user_id}/`)
    .then((response) => {
      if (response.status === 200) {
      }
    })
    .catch((error) => {
      console.log('user profile not loaded');
      return error;
    });

  return response;
};

export const get_user_details = async (user_id) => {
  console.log(user_id);
  const response = await api_base
    .get(`users/${user_id}/`)
    .then((response) => {
      if (response.status === 200) {
        console.log(`details for user ${user_id} loaded`);
        return response;
      }
    })
    .catch((error) => {
      console.log('user details not loaded');
      return error;
    });

  return response;
};

export const edit_user = async (user_id, edited_user) => {
  const response = await api_base
    .patch(`users/${user_id}/`, edited_user)
    .then((response) => {
      if (response.status === 200) {
        console.log(`details for user ${user_id} edited`);
        return response;
      }
    })
    .catch((error) => {
      console.log('failed to edit user');
      return error;
    });

  return response;
};

export const create_user = async (user_info) => {
  const response = await api_base
    .post('users/', user_info)
    .then((response, reject) => {
      if (response.status === 201) {
        console.log('user created');
        return response;
      }
    })
    .catch(handle_error);

  return response;
};

export function getServicesById(facility_id) {
  return (
    axios
      // .get(`${baseURL}patients/appointments/4`)
      .get('http://localhost:8000/patients/appointments/4')
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          return response;
        }
      })
      .catch(handle_error)
  );
}

export const create_facility = async (facility_info) => {
  const form_data = new FormData();
  for (const key in facility_info) {
    form_data.append(key, facility_info[key]);
  }
  // TODO save facility logo
  const response = await api_base
    .post('/facilities/', facility_info)
    .then((response) => {
      if (response.status === 201) {
        console.log('facility created:', response.data);
        return response;
      }
    })
    .catch(handle_error);

  return response;
};

export const get_users_by_facility = async (data) => {
  const facility_id = get_facility_id();
  let param = objectToParam(data);
  const response = await axios
    .get(`${baseURL}users/facility/${facility_id}/?${param}`)
    .then((response) => {
      if (response.status === 200) {
        console.log(`users from ${facility_id} gotten`);
        return response;
      }
    })
    .catch((error) => {
      console.log('error:', error);
      return error;
    });

  return response;
};

export const delete_user = async (id) => {
  const response = await axios
    .delete(`${baseURL}users/${id}/`)
    .then((response) => {
      if (response.status === 200) {
        console.log(`user ${id} has been deleted`);
        return response;
      }
    })
    .catch(handle_error);

  return response;
};

export const get_facility_details = async () => {
  const id = get_facility_id();
  const response = await axios
    .get(`${baseURL}facilities/${id}/`)
    .then((response) => {
      // console.log(response)
      if (response.status === 200) {
        console.log(`details for ${id} loaded`);
        return response;
      }
    })
    .catch((error) => {
      console.log('error:', error);
      return error;
    });

  return response;
};

/**
 * Description. Pulls appointments based on logged facility
 * @author Martin Wachira <https://github.com/martinwachira>
 * @return {array} array
 */

export const get_appointments_by_facility = async (department) => {
  const id = get_facility_id();
  const response = await axios
    .get(
      `${baseURL}patients/appointments/facility/${id}/?department=${department}&status=In_progress`,
    )
    .then((response) => {
      // console.table(response.data)
      if (response.status === 200) {
        console.log(`appointments for ${id} are loaded`);
        return response;
      }
    })
    .catch(handle_error);

  return response;
};

export const get_assigned_appointments_by_facility = async (department) => {
  console.log(baseURL);
  const id = get_facility_id();
  const response = await axios
    .get(
      `${baseURL}patients/appointments/facility/${id}/?department=${department}&assigned=True`,
    )
    .then((response) => {
      // console.table(response.data)
      if (response.status === 200) {
        console.log(`appointments for ${id} are loaded`);
        return response;
      }
    })
    .catch(handle_error);

  return response;
};

export const assign_appointment = async (user_id, appointment_id) => {
  const body = {
    user_id: user_id,
  };
  const response = await axios
    .patch(`${baseURL}patients/appointments/assign/${appointment_id}/`, body)
    .then((response) => {
      if (response.status === 202) {
        return response;
      }
    })
    .catch(handle_error);

  return response;
};

export const get_pending_appointments_by_facility = async () => {
  const id = get_facility_id();
  const response = await axios
    .get(`${baseURL}patients/appointments/facility/pending/${id}/`)
    .then((response) => {
      if (response.status === 200) {
        console.log(`appointments for ${id} are loaded`);
        return response;
      }
    })
    .catch(handle_error);

  return response;
};

export const get_submitted_appointments_by_facility = async () => {
  const id = get_facility_id();
  const response = await axios
    .get(`${baseURL}patients/appointments/facility/submitted/${id}/`)
    .then((response) => {
      if (response.status === 200) {
        console.log(`appointments for ${id} are loaded`);
        return response;
      }
    })
    .catch(handle_error);

  return response;
};

export const change_appointment_status = async (id, new_status) => {
  const object = {
    status: new_status,
  };
  const response = await axios
    .patch(`${baseURL}patients/appointments/${id}/`, object)
    .then((response) => {
      if (response.status === 200) {
        console.log(`status of appointment ${id} has been changed`);
        return response;
      }
    })
    .catch(handle_error);

  return response;
};

export const submit_appointment = async (id, reports) => {
  const form_data = new FormData();
  for (const i in reports) {
    form_data.append(i, reports[i]);
  }
  form_data.append('is_submitted', true);

  const response = await axios
    .post(`${baseURL}patients/appointments/submit/${id}/`, form_data)
    .then((response) => {
      if (response.status === 200) {
        console.log(`reprts submitted for appointment ${id}`);
        return response;
      }
    })
    .catch(handle_error);

  return response;
};

export const resend_appointment = async (id) => {
  const response = await axios
    .post(`${baseURL}patients/appointments/resubmit/${id}/`)
    .then((res) => {
      // console.log('sent details 2nd', res)
      if (res.status === 200) {
        console.log(`reports resubmitted for appointment ${id}`);
        return res;
      }
    })
    .catch(handle_error);

  return response;
};

/**
 * Description. Pull services from concierge
 * @author Martin Wachira <https://github.com/martinwachira>
 * @return {array} array
 */

// call concierge to get services
export const get_services = async (facility) => {
  const externalId = getExternalId();
  const servicesUrl = conciergeSUrl;
  const res = await axios
    .post(auth_url, auth_body)
    .then(async (res) => {
      if (res.status === 200) {
        const getServices = await axios
          .get(
            `${servicesUrl}?provider_id=${externalId}&provider_type=1&limit=5000`,
            {
              headers: {
                Authorization: `Bearer ${res.data}`,
              },
            },
          )
          .then((getServices) => {
            // console.log('all services = ', getServices.data);
            return getServices;
          })
          .catch((error) => error);
        return getServices;
      }
    })
    .catch((error) => error);
  return res;
};

/**
 * Description. Pull packages from concierge
 * @author Martin Wachira <https://github.com/martinwachira>
 * @return {Promise} promise
 */

// call concierge to get packages
export const get_packages = async (facility) => {
  const externalId = getExternalId();
  const packagesUrl = conciergePUrl;
  const res = await axios
    .post(auth_url, auth_body)
    .then(async (res) => {
      if (res.status === 200) {
        const getPackages = await axios
          .get(
            `${packagesUrl}?provider_id=${externalId}&provider_type=1&limit=1000`,
            {
              headers: {
                Authorization: `Bearer ${res.data}`,
              },
            },
          )
          .then((getPackages) => {
            // console.log('all packages = ', getPackages.data);
            return getPackages;
          })
          .catch((error) => error);
        return getPackages;
      }
    })
    .catch((error) => error);
  return res;
};

export const download_lpo = async (uuid, external_id) => {
  if (!uuid) {
    const appointment = await axios
      .post(`${baseURL}patients/appointments/update/by_id/${external_id}`)
      .then((response) => {
        if (response.status === 200) {
          uuid = response.data.data.lpo_uuid;
          return response;
        }
      })
      .catch((error) => {
        console.log('error occured');
      });
  }
  if (!uuid) {
    return false;
  }

  const final_response = await axios
    .post(auth_url, auth_body)
    .then(async (res) => {
      if (res.status === 200) {
        const got_lpo = await axios
          .post(
            `https://uat.concierge.ponea.com/api/lpo/download/${uuid}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${res.data}`,
              },
            },
          )
          .then((response) => {
            const path = response.data.path;
			const final_path = `https://uat.concierge.ponea.com${path}`
            window.open(final_path, '_blank');
            return true;
          })
          .catch((error) => {
            return false;
          });
        return got_lpo;
      }
    })
    .catch((error) => {
      error;
    });

  return final_response;
};
