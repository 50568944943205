import {
  FACILITY_REGISTRATION_STEP_1,
  FACILITY_REGISTRATION_STEP_2,
  FACILITY_REGISTRATION_DATA_RESET,
  FACILITY_REGISTRATION_STEP_3,
  FACILITY_REGISTRATION_STEP_4,
  FACILITY_REGISTRATION_CHANGE_STEP,
} from './actionTypes';

/**
 * Reducer for Registration
 *
 * Description. Keep registration data in redux state
 * @author Sourav Ghosh.
 */

const initialState = {
  step: { step1: false, step2: false, step3: false, step4: false },
  activeForm: '1',
  address_object: {},
  admin_object: {},
  contact_people_object: {
    contact_people: [
      { id: 0, name: '', designation: '', email: '', phone: '', calling_code : '' },
    ],
  },
  facility_object: {},
  holiday_object: { holidays: [] },
  length: 8,
  logo: '',
  schedule_object: { schedules: [] },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FACILITY_REGISTRATION_STEP_1:
      return {
        ...state,
        admin_object: {
          ...action.payload.admin_object,
          department: 'administrator',
        },
      };
    case FACILITY_REGISTRATION_STEP_2:
      return {
        ...state,
        facility_object: action.payload.facility_object,
      };
    case FACILITY_REGISTRATION_STEP_3:
      return {
        ...state,
        address_object: action.payload.address_object,
        contact_people_object: action.payload.contact_people_object,
      };
    case FACILITY_REGISTRATION_STEP_4:
      return {
        ...state,
        schedule_object: action.payload.schedule_object,
        holiday_object: action.payload.holiday_object,
      };
    case FACILITY_REGISTRATION_CHANGE_STEP:
      // console.log('FACILITY_REGISTRATION_CHANGE_ACTIVE_FORM', action.payload.activeForm)
      return {
        ...state,
        step: action.payload.step,
      };
    case FACILITY_REGISTRATION_DATA_RESET:
      // console.log('FACILITY_REGISTRATION_CHANGE_ACTIVE_FORM', action.payload.activeForm)
      return {
        ...state,
        step: { step1: false, step2: false, step3: false, step4: false },
        activeForm: '1',
        address_object: {},
        admin_object: {},
        contact_people_object: {
          contact_people: [
            { id: 0, name: '', designation: '', email: '', phone: '' },
          ],
        },
        facility_object: {},
        holiday_object: { holidays: [] },
        length: 8,
        logo: '',
        schedule_object: { schedules: [] },
      };

    default:
      return state;
  }
};
