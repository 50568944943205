import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { NavLink } from 'react-router-dom';
import { useHistory, Redirect } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import {
  Build,
  Home,
  ImportContacts,
  ShoppingCart,
  Accessibility,
  AttachMoney,
  Person,
  Security,
  Domain,
  LocalOffer,
  Receipt,
  PeopleAlt,
  Layers,
  Settings,
} from '@material-ui/icons';
import { get_facility_details } from './../../utilities/api/apiCalls';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    marginTop: 10,
    color: '#323F4A',
    padding: '0px',
    position: 'relative',
    borderBottomColor: '#ccc',
    backgroundColor: '#F9F5F5',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function NestedList() {
  const history = useHistory();
  const classes = useStyles();
  let pathname = history.location.pathname;
  const [open, setOpen] = React.useState(
    pathname == '/admin/physio-services' ||
      pathname == '/admin/laboratory' ||
      pathname == '/admin/packages',
  );
  const [open2, setOpen2] = React.useState(pathname == '/admin/users');
  const [openDashboard, setOpenDashboard] = React.useState(pathname == '/admin/dashboard');
  const [facilitydetails, setFacilityDetails] = useState({});

  const handleClick = () => {
    setOpen(!open);
    setOpen2(false);
    setOpenDashboard(false);

  };

  const handleClick2 = () => {
    setOpen2(!open2);
    setOpen(false);
    setOpenDashboard(false);

  };
  const handleClickDashboard = () => {
    setOpen2(false);
    setOpen(false);
    setOpenDashboard(!openDashboard);
  };

  useEffect(() => {
    const getFacilityDetails = async () => {
      await get_facility_details()
        .then((res) => {
          setFacilityDetails(res.data.facility_object);
        })
        .catch((error) => {
          return error;
        });
    };
    getFacilityDetails();
  }, []);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={[classes.root, 'lab_theme']}>
      
      <ListItem button onClick={handleClickDashboard}>
        <ListItemIcon>
          <Home fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
        {openDashboard ? (
          <ExpandLess style={{ color: '#f0625c' }} />
        ) : (
          <ExpandMore style={{ color: '#f0625c' }} />
        )}
      </ListItem>
      <Collapse in={openDashboard} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <NavLink
        style={{ borderBottomWidth: '1px' }}
        exact
        activeClassName="activeClass"
        to="/admin/dashboard">
        <ListItem button>
          <ListItemIcon>
            {(pathname === "/admin/financialDashboard")?
             <img src='/clinincal-home-black.png' alt="home icon BLACK" className="ml-1" />
            :
             <img src='/clinincal-home.png' alt="home icon" className="ml-1" />
            }
            {/* <Home fontSize="small" /> */}
          </ListItemIcon>

          <ListItemText primary="Clinical Dashboard" />
        </ListItem>
      </NavLink>
      <NavLink
        style={{ borderBottomWidth: '1px' }}
        exact
        activeClassName="activeClass"
        to="/admin/financialDashboard">
        <ListItem button>
          <ListItemIcon>
            <AttachMoney fontSize="small" />
          </ListItemIcon>

          <ListItemText primary="Financial Dashboard" />
        </ListItem>
      </NavLink>
        </List>
      </Collapse>
      
      
    
      <NavLink
        style={{ borderBottomWidth: '1px' }}
        exact
        activeClassName="activeClass"
        to="/admin/set-up">
        <ListItem button>
          <ListItemIcon>
            <Build fontSize="small" />
          </ListItemIcon>

          <ListItemText primary={'Setup'} />
        </ListItem>
      </NavLink>

      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <ImportContacts fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Service & Pricing Catalogue" />
        {open ? (
          <ExpandLess style={{ color: '#f0625c' }} />
        ) : (
          <ExpandMore style={{ color: '#f0625c' }} />
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {facilitydetails && facilitydetails.laboratory === true && (
            <NavLink
              onClick={(e) => {
                e.preventDefault();
                history.push('/admin/laboratory');
              }}
              style={{ borderBottomWidth: '1px' }}
              exact
              activeClassName="activeClass"
              to="/admin/laboratory">
              <ListItem button>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Laboratory" />
              </ListItem>
            </NavLink>
          )}
          {facilitydetails && facilitydetails.physiotherapy === true && (
            <NavLink
              style={{ borderBottomWidth: '1px' }}
              exact
              activeClassName="activeClass"
              to="/admin/physio-services">
              <ListItem button>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Physiotherapy" />
              </ListItem>
            </NavLink>
          )}
          {facilitydetails && facilitydetails.doctor === true && (
            <NavLink
              style={{ borderBottomWidth: '1px' }}
              exact
              activeClassName="activeClass"
              to="/admin/doctor-services">
              <ListItem button>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Doctor" />
              </ListItem>
            </NavLink>
          )}
          <NavLink
            style={{ borderBottomWidth: '1px' }}
            exact
            activeClassName="activeClass"
            to="/admin/packages">
            <ListItem button>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Packages" />
            </ListItem>
          </NavLink>
        </List>
      </Collapse>
      <ListItem button onClick={handleClick2}>
        <ListItemIcon>
          <Accessibility fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Users" />
        {open2 ? (
          <ExpandLess style={{ color: '#f0625c' }} />
        ) : (
          <ExpandMore style={{ color: '#f0625c' }} />
        )}
      </ListItem>
      <Collapse in={open2} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink
            onClick={(e) => {
              e.preventDefault();
              history.push('/admin/users');
            }}
            style={{ borderBottomWidth: '1px' }}
            exact
            activeClassName="activeClass"
            to="/admin/users">
            <ListItem button>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="All Users" />
            </ListItem>
          </NavLink>
        </List>
      </Collapse>
    </List>
  );
}
