import React from 'react';
import {Route,Redirect} from 'react-router-dom';
import {createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import Layout from '../components/Laboratory/theme/Layout';

const checkIsLogged = () => { 
   
}
const theme = createTheme({
  palette: {
    primary: {
      light: '#333a4c',
      main: '#0d1424',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f3817c',
      main: '#F0625C',
      dark: '#a84440',
      contrastText: '#fff',
    },
    white: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#fff',
    },
    error: {
      light: '#ff7663',
      main: '#FF543D',
      dark: '#b23a2a',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: "'galanogrotesque', sans-serif",
    fontSize: 16,
    fontStyle: '400',
    fontDisplay: 'block',
  },
  tooltip: {
    backgroundColor: '#333',
    color: '#fff',
    fontSize: 11,
  },
});
export const LaboratoryRoute = ({ component: Component, ...rest }) => (

    <Route {...rest} render={(props) => {checkIsLogged();return(
      (true) 
        ? <Component {...props} />
        : <Redirect to={{
            pathname: '/',
            state: { from: props.location }
          }} />
    )}} />
  
);