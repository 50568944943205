import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { NavLink } from 'react-router-dom';
import { useHistory, Redirect } from 'react-router-dom';
import {   
    Typography,
  } from '@material-ui/core';
import {
  Build,
  Home,
  Mail,
  ImportContacts,
  ShoppingCart,
  Accessibility,
  Person,
  Security,
  Domain,
  LocalOffer,
  Receipt,
  PeopleAlt,
  Layers,
  Settings,
} from '@material-ui/icons';
import ChatIcon from '@material-ui/icons/Chat';
import {
  Dashboard,
  Dns,
  LowPriority,
  DeviceHubOutlined,
  DateRange,
} from "@material-ui/icons";
import ViewComfyIcon from '@material-ui/icons/ViewComfy';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    marginTop: 10,
    color: '#323F4A',
    padding: '0px',
    position: 'relative',

    borderBottomColor: '#ccc',
    backgroundColor: '#F9F5F5',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function NestedList() {
const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  

  const handleClick = () => {
    setOpen(!open);
    setOpen2(false);
  };

  const handleClick2 = () => {
    setOpen2(!open2);
    setOpen(false);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={[classes.root, 'doctor_theme']}>
      <NavLink
        style={{ borderBottomWidth: '1px' }}
        exact
        activeClassName="activeClass"
        to="/doctor-user/dashboard">
        <ListItem button>
          <ListItemIcon>
            <Home fontSize="small" color="red" />
          </ListItemIcon>

          <ListItemText primary="Dashboard" />
        </ListItem>
      </NavLink>

      <NavLink
        style={{ borderBottomWidth: '1px' }}
        exact
        activeClassName="activeClass"
        to="/doctor-user/queues">
        <ListItem button>
          <ListItemIcon>
            <ViewComfyIcon  fontSize="small"/>
          </ListItemIcon>

          <ListItemText primary={'Queues'}  />
        </ListItem>
      </NavLink>

      <NavLink
        style={{ borderBottomWidth: '1px' }}
        exact
        activeClassName="activeClass"
        to="/doctor-user/consults">
        <ListItem button>
          <ListItemIcon>
            <ChatIcon  fontSize="small"/>
          </ListItemIcon>

          <ListItemText primary={'Consults'}  />
        </ListItem>
      </NavLink>    
      {/* <NavLink
        style={{ borderBottomWidth: '1px' }}
        exact
        activeClassName="activeClass"
        to="/doctor-user/messages">
        <ListItem button>
          <ListItemIcon>
            <Mail  fontSize="small"/>
          </ListItemIcon>

          <ListItemText primary={'Messages'}  />
        </ListItem>
      </NavLink>  */}
    </List>
  );
}
