import {
  SET_USER_DATA,
  SET_AUTH_USER_DATA,
  SET_HISTORY_FILTER,
  UNSET_USER_DATA,
  SET_CONCIERGE_TOEKN,
  SET_CALLCENTER_TOEKN,
} from './actionTypes';
import {get_django_date_format2,} from '../../utilities/dateTimeFunctions';

/**
 * Reducer for Auth user
 *
 * Description. Keep registration data in redux state
 * @author Sourav Ghosh.
 */

const initialState = {
  userData: {},
  callcenter_token: '',
  concierge_token: '',
  department: '',
  access_token: '',
  sign_out: false,
  isAuthenticated: false,
  isCollapsed: false,
  historyFilter:{
    page: 1,
    limit: 10,
    from_date: get_django_date_format2(new Date()),
    to_date: get_django_date_format2(new Date()),
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload.userData,
        access_token: action.payload.access_token,
        isAuthenticated: true,
        sign_out: false,
      };

    case SET_AUTH_USER_DATA:
      return {
        ...state,
        userData: action.payload.userData,
        department: action.payload.userData.department,
        isAuthenticated: true,
        sign_out: false,
      };
    case UNSET_USER_DATA:
		const new_state = undefined
      return {
        state: new_state,
        userData: {},
        department: '',
        sign_out: true,
        isAuthenticated: false,
        concierge_token: '',
      };

    case SET_CONCIERGE_TOEKN:
      return {
        ...state,
        concierge_token: action.payload.concierge_token,
      };
    case SET_CALLCENTER_TOEKN:
      return {
        ...state,
        callcenter_token: action.payload.callcenter_token,
      };
    case SET_HISTORY_FILTER:
      return {
        ...state,
        historyFilter: action.payload.filterData,
      };

    default:
      return state;
  }
};
