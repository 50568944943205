import {
  SET_USER_DATA,
  UNSET_USER_DATA,
  SET_AUTH_USER_DATA,
  SET_CONCIERGE_TOEKN,
  SET_CALLCENTER_TOEKN,
  SET_HISTORY_FILTER,
} from './actionTypes';

export function setCallcenterToken(callcenter_token) {
  const payload = {
    callcenter_token,
  };
  return (dispatch) => {
    returnToDispatch(dispatch, SET_CALLCENTER_TOEKN, payload);
  };
}


export function setConciergeToken(concierge_token) {
  const payload = {
    concierge_token,
  };
  return (dispatch) => {
    returnToDispatch(dispatch, SET_CONCIERGE_TOEKN, payload);
  };
}
/**
 * Set User profile to redux
 *
 * Description. Save user profile to redux
 * @author Sourav Ghosh.
 */

export function setUserdata(userData, access_token) {
  const payload = {
    userData,
    access_token,
  };
  return (dispatch) => {
    returnToDispatch(dispatch, SET_USER_DATA, payload);
  };
}

export function signOut() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('stay_logged_in');
  sessionStorage.removeItem('access_token');

  const payload = {};
  return (dispatch) => {
    returnToDispatch(dispatch, UNSET_USER_DATA, payload);
  };
}

export function setAuthUserdata(userData, access_token) {
  const payload = {
    userData,
  };
  return (dispatch) => {
    returnToDispatch(dispatch, SET_AUTH_USER_DATA, payload);
  };
}

export function setHistoryFilter(filterData, access_token) {
  const payload = {
        filterData
      };
  return (dispatch) => {
    returnToDispatch(dispatch, SET_HISTORY_FILTER, payload);
  };
}

export function returnToDispatch(dispatch, type, payload) {
  dispatch({
    type,
    payload,
  });
}
