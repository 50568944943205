import React from 'react'
import { Box} from '@material-ui/core';
import { Container} from 'react-bootstrap';
import { useHistory } from "react-router-dom";

const NotFound = ()=>{
  const history = useHistory(); 
  return (
    <>
      <Container as="section" bsPrefix="form-wraper">
        <Box className="box">
          <Box className="box-body text-center">
            <img src="404.jpg" alt="Under Construction" className="w-100" onClick={event =>history.goBack()}/>
          </Box>
        </Box>        
      </Container>
    </>
  )
	
}

export default NotFound;