import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { NavLink } from 'react-router-dom';
import { useHistory, Redirect } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import {
  Build,
  Home,
  ImportContacts,
  ShoppingCart,
  Accessibility,
  Person,
  Security,
  Domain,
  LocalOffer,
  Receipt,
  PeopleAlt,
  Layers,
  Settings,
} from '@material-ui/icons';

import {
  Dashboard,
  Dns,
  LowPriority,
  DeviceHubOutlined,
  DateRange,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    marginTop: 10,
    color: '#323F4A',
    padding: '0px',
    position: 'relative',

    borderBottomColor: '#ccc',
    backgroundColor: '#F9F5F5',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function UserNav() {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
    setOpen2(false);
  };

  const handleClick2 = () => {
    setOpen2(!open2);
    setOpen(false);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={[classes.root, 'physico_theme']}>
      <NavLink
        style={{ borderBottomWidth: '1px' }}
        exact
        activeClassName="activeClass"
        to="/physio-user/dashboard">
        <ListItem button>
          <ListItemIcon>
            <Dashboard fontSize="small" />
          </ListItemIcon>

          <ListItemText primary="Dashboard" />
        </ListItem>
      </NavLink>

      <NavLink
        style={{ borderBottomWidth: '1px' }}
        exact
        activeClassName="activeClass"
        to="/physio-user/work-orders">
        <ListItem button>
          <ListItemIcon>
            <Dns fontSize="small" />
          </ListItemIcon>

          <ListItemText primary={'Work Orders'} />
        </ListItem>
      </NavLink>

      <NavLink
        style={{ borderBottomWidth: '1px' }}
        exact
        activeClassName="activeClass"
        to="/physio-user/assigned">
        <ListItem button>
          <ListItemIcon>
            <LowPriority fontSize="small" />
          </ListItemIcon>

          <ListItemText primary={'History'} />
        </ListItem>
      </NavLink>
    </List>
  );
}
