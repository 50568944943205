import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { NavLink } from 'react-router-dom';
import { useHistory, Redirect } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import {
  Build,
  Home,
  ImportContacts,
  ShoppingCart,
  Accessibility,
  Person,
  Security,
  Domain,
  LocalOffer,
  Receipt,
  PeopleAlt,
  Layers,
  Settings,
} from '@material-ui/icons';

import {
  Dashboard,
  Dns,
  DeviceHubOutlined,
  DateRange,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    marginTop: 10,
    color: '#323F4A',
    padding: '0px',
    position: 'relative',

    borderBottomColor: '#ccc',
    backgroundColor: '#F9F5F5',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function NestedList(props) {
  const history = useHistory();
  let pathname = history.location.pathname;
  const classes = useStyles();
  const [open, setOpen] = React.useState(
    pathname == '/doctor/supervisor/work-orders' ||
      pathname == '/physio/user-work-orders' ||
      pathname == '/physio/user-assigned',
  );

  const handleClick = () => {
    setOpen(!open);
    props.updateMenu()
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={[classes.root, 'doctor_theme']}>
      <NavLink
        style={{ borderBottomWidth: '1px' }}
        exact
        onClick={()=>props.updateMenu()}
        activeClassName="activeClass"
        to="/doctor/supervisor/dashboard">
        <ListItem button>
          <ListItemIcon>
            <Dashboard fontSize="small" />
          </ListItemIcon>

          <ListItemText primary="Dashboard" />
        </ListItem>
      </NavLink>

      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <ImportContacts fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Work Orders" />
        {open ? (
          <ExpandLess style={{ color: '#69cbe1' }} />
        ) : (
          <ExpandMore style={{ color: '#69cbe1' }} />
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink
            style={{ borderBottomWidth: '1px' }}
            exact
            onClick={()=>props.updateMenu()}
            activeClassName="activeClass"
            to="/doctor/all/work-orders">
            <ListItem button>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="All Work Orders" />
            </ListItem>
          </NavLink>
          <NavLink
            onClick={(e) => {
              e.preventDefault();
              props.updateMenu()
              history.push('/doctor/supervisor/work-orders');
            }}
            style={{ borderBottomWidth: '1px' }}
            exact
            activeClassName="activeClass"
            to="/doctor/supervisor/work-orders">
            <ListItem button>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="My Work Orders" />
            </ListItem>
          </NavLink>
          <NavLink
            style={{ borderBottomWidth: '1px' }}
            exact
            activeClassName="activeClass"
            onClick={(e) => {
              e.preventDefault();
              props.updateMenu()
              history.push('/doctor/supervisor/assigned');
            }}
            to="/doctor/supervisor/assigned">
            <ListItem button>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="My History" />
            </ListItem>
          </NavLink>
        </List>
      </Collapse>
      <NavLink
        style={{ borderBottomWidth: '1px' }}
        exact
        activeClassName="activeClass"
        onClick={(e) => {
          e.preventDefault();
          props.updateMenu()
          history.push('/doctor/supervisor/work-list');
        }}
        to="/doctor/supervisor/work-list">
        <ListItem button>
          <ListItemIcon>
            <Dns fontSize="small" />
          </ListItemIcon>

          <ListItemText primary={'Work List'} />
        </ListItem>
      </NavLink>
    </List>
  );
}
