import React from 'react';
import {
  makeStyles,
  Box,
  IconButton,
  Fab,
  Button,
  Link,
} from '@material-ui/core';
import { NotificationsNone } from '@material-ui/icons';
import { signOut } from '../../../reducers/Auth/action';
import { connect } from 'react-redux';
import { AuthApp } from '../../../checkAuthenticationHoc';
import { useHistory, Redirect } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


const white = '#fff';
const headerStyle = makeStyles({
  '@global': {
    header: {
      width: '100%',
      height: '4.375rem',
      padding: '0 0.5rem 0 2.5rem',
      boxShadow: 'rgba(0,0,0,0.16) 0 0.1875rem 1.25rem',
      position: 'fixed',
      zIndex: 10,
      display: 'flex',
      alignItems: 'center',
      '& .brand': {
        maxWidth: '20rem',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& a': {
          width: '100%',
          height: '100%',
          display: 'flex',
          '&  img': { width: '100%', height: 'auto' },
        },
      },
      '& .topBtn': {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
      },
      '& button': {
        '&.MuiButton-root,&.MuiIconButton-root': { color: white },
      },
      '& .MuiBox-root-475': {
        color: white,
        backgroundColor: '#000',
      },
    },
  },
});

function Header(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const classes = headerStyle();

  if (props.isAuthenticated == false && props.sign_out == true) {
    history.push('/');
  }

  const Logout = () => {
    props.signOut();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        component="header"
        bgcolor="primary.main"
        color="primary.contrastText"
        className={classes.header}>
        <Box className="brand">
          <Link href="/#/lab/dashboard">
            <img src="phl-logo.png" alt="" />
          </Link>
        </Box>
        <span class="mobile_menu">
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}>
            <MenuIcon style={{ color: 'white' }} />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>Notifications</MenuItem>
            <MenuItem onClick={()=>{
              handleClose()
              props.signOut()
            }}>Logout</MenuItem>
          </Menu>
        </span>
        <Box component="div" className="topBtn desktop_menu">
          <Fab size="small" aria-label="user">
            <img src="user.jpg" alt="" />
          </Fab>
          <IconButton aria-label="notification">
            <NotificationsNone />
          </IconButton>
          <Button size="small" onClick={(event) => Logout()}>
            Sign Out
          </Button>
        </Box>
      </Box>
    </>
  );
}
const mapStateToProps = (state, props) => {
  const { isAuthenticated, sign_out } = state.userData;
  return {
    sign_out,
    isAuthenticated,
  };
};
export default connect(mapStateToProps, { signOut })(AuthApp(Header));
