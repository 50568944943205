import React from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  TimePicker,
} from "@material-ui/pickers";
// import CreatableSelect from 'react-select/creatable';
import AsyncCreatable from "react-select/async-creatable";
// import Async from 'react-select/async';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Async from 'react-select/async';
import {
  IconButton,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Button,
  Radio,
  TextField,
} from '@material-ui/core';

const theme = createTheme({
  palette: {
      primary: {
          light: '#5e5e5e',
          main: '#363636',
          dark: '#252525',
          contrastText: '#fff',
      },
      secondary: {
          light: '#ff7674',
          main: '#ff3d48',
          dark: '#c40020',
          contrastText: '#fff',
      },
      error: {
          light: '#ff7663',
          main: '#FF543D',
          dark: '#b23a2a',
          contrastText: '#fff',
      },
  },
});


function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} className="Outlined-Select" />;
}
function Control(props) {
  return (
    <TextField
      fullWidth
      variant="outlined"
      InputProps={{
        inputComponent,
        inputProps: {
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}
const Option = (props) => (
    <MenuItem
      className="select2-option"
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
const components = {
  Option,
  Control,
};
function noOptionsMessage() {
  return null;
}

  export const renderAsyncSelect2Input = ({
    input,
    label,
    meta: { touched, error },
    placeholder,
    required,
    isMulti,
    defaultValue,
    fetchOptions,
    isClearable,
    initialOptions,
    menuIsOpen,
    isDisabled,
    isOptionDisabled,
    size,
    colors,
}) => (
    <MuiThemeProvider theme={theme}>
        <FormGroup
            className={touched && error ? 'form-group invalid ' : 'form-group'}
        >
            <Async
                error={!!(touched && error)}
                helperText={touched && error}
                {...input}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                components={components}
                loadOptions={fetchOptions}
                defaultOptions={initialOptions || []}
                placeholder={placeholder}
                color={colors}
                size={size}
                onChange={(value) => input.onChange(value)}
                onBlur={(event) => event.preventDefault()}
                onFocus={() => input.onFocus()}
                selectedValue={defaultValue}
                isDisabled={isDisabled || false}
                isOptionDisabled={isOptionDisabled || null}
                value={input.value}
                isMulti={isMulti}
                noOptionsMessage={noOptionsMessage}
                isClearable={isClearable || false}
                menuIsOpen={menuIsOpen}
                textFieldProps={{
                    label,
                    required,
                    InputLabelProps: {
                        shrink: true,
                    },
                }}
            />
            <div className="text-help">{touched ? error : ''}</div>
        </FormGroup>
    </MuiThemeProvider>
);  

export const renderTextInput = ({className, inputClass,disabled,input,label, variant, meta: { touched, error },size, color, type, readonly, required, placeholder,}) => 
  // console.log("className==============", className, input.value)
   (
  <div className={className}>
    <TextField
      className={inputClass}
      error={!!(touched && error)}
      label={required ? `${label  } *` : label}
      placeholder={placeholder || ""}
      type={type}
      size={size}
      color={color}
      variant={variant}
      helperText={touched && error}
      fullWidth
      {...input}
      disabled={disabled ? (disabled === "true") : false}
      value={input.value}
      InputProps={{ readOnly: !!readonly }}
    />
  </div>
);

export const prefixTextInput = ({
  className,
  inputClass,
  disabled,
  input,
  label,
  meta: { touched, error },
  size,
  color,
  variant,
  type,
  readonly,
  required,
  placeholder,
  position,
  prefix,
}) => (
  <div className={className}>
    <TextField
      className={inputClass}
      error={!!(touched && error)}
      label={required ? `${label  } *` : label}
      placeholder={placeholder || ""}
      type={type}
      size={size}
      color={color}
      variant={variant}
      helperText={touched && error}
      fullWidth
      {...input}
      disabled={disabled ? (disabled === "true") : false}
      value={input.value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{prefix}</InputAdornment>
        ),
      }}
    />
  </div>
);

export const suffixTextInput = ({
  className,
  inputClass,
  disabled,
  input,
  label,
  meta: { touched, error },
  size,
  color,
  variant,
  type,
  readonly,
  required,
  placeholder,
  suffix,
}) => (
  <div className={className}>
    <TextField
      className={inputClass}
      error={!!(touched && error)}
      label={required ? `${label  } *` : label}
      placeholder={placeholder || ""}
      type={type}
      size={size}
      color={color}
      variant={variant}
      helperText={touched && error}
      fullWidth
      {...input}
      disabled={disabled ? (disabled === "true") : false}
      value={input.value}
      InputProps={{
        endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
      }}
    />
  </div>
);

function PriceFieldComponent(props) {
  const { inputRef, ...other } = props;
  return (
    <NumberFormat
      style={{ textAlign: "right" }}
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      allowNegative={false}
      thousandSeparator
      prefix="$"
    />
  );
}


// export const renderTextAreaInputForDoctorNotes = ({
//     input,
//     label,
//     meta: { touched, error },
//     variant,
//     required,
//     rows,
//     disabled,
// }) => (
//     <MuiThemeProvider theme={theme}>
//         <div className="form-group">
//             <TextField
//                 error={!!(touched && error)}
//                 label={required ? label + ' *' : label}
//                 variant={variant}
//                 multiline
//                 rows={rows ? rows : 4}
//                 margin="none"
//                 className="doctor_notes"
//                 helperText={touched && error}
//                 disabled={disabled ? disabled : false}
//                 fullWidth
//                 {...input}
//                 value={input.value}
//             />
//         </div>
//     </MuiThemeProvider>
// );

PriceFieldComponent.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
export const renderPriceInput = ({
  className,
  inputClass,
  disabled,
  input,
  label,
  meta: { touched, error },
  size,
  color,
  variant,
  type,
  required,
  placeholder,
}) => (
  <div className={className}>
    <TextField
      className={inputClass}
      error={!!(touched && error)}
      label={required ? `${label  } *` : label}
      placeholder={placeholder || ""}
      type={type}
      size={size}
      color={color}
      variant={variant}
      helperText={touched && error}
      fullWidth
      {...input}
      value={input.value}
      disabled={disabled ? (disabled === "true") : false}
      InputProps={{ inputComponent: PriceFieldComponent }}
    />
  </div>
);

function getMinDate(n) {
  const dt = new Date();
  return new Date(dt.setFullYear(dt.getFullYear() - n));
}
function getMaxDate(n) {
  const dt = new Date();
  return new Date(dt.setFullYear(dt.getFullYear() + n));
}
export const renderDateInput = ({
  className,
  inputClass,
  disabled,
  input,
  label,
  meta: { touched, error },
  size,
  color,
  orientation,
  variant,
  inputVariant,
  type,
  maxDate,
  minDate,
  minYear,
  maxYear,
  required,
  placeholder,
  format,
}) => (
  <div className={className}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={inputClass}
        error={!!(touched && error)}
        label={required ? `${label  } *` : label}
        keyboard
        // format="dd/MM/yyyy"
        format="yyyy-MM-dd"
        placeholder={placeholder}
        type={type}
        size={size}
        disabled={disabled}
        color={color}
        variant={variant}
        orientation={orientation}
        inputVariant={inputVariant}
        helperText={touched && error}
        fullWidth
        {...input}
        mask={(value) =>
          value
            ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
            : []
        }
        animateYearScrolling={false}
        value={input.value ? input.value : null}
        onChange={(value) => input.onChange(value)}
        maxDate={maxDate || getMaxDate(maxYear || 10)}
        minDate={minDate || getMinDate(minYear || 10)}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  </div>
);

export const renderTimeInput = ({
  className,
  inputClass,
  input,
  label,
  meta: { touched, error },
  keyboard,
  ampm,
  size,
  color,
  variant,
  inputVariant,
  type,
  required,
  placeholder,
  icon,
}) => (
  <div className={className}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        className={inputClass}
        error={!!(touched && error)}
        label={required ? `${label  } *` : label}
        keyboard={keyboard}
        ampm={ampm}
        placeholder={placeholder}
        type={type}
        size={size}
        color={color}
        variant={variant}
        inputVariant={inputVariant}
        helperText={touched && error}
        fullWidth
        {...input}
        mask={[/\d/, /\d/, ":", /\d/, /\d/, " ", /a|p/i, "M"]}
        value={input.value ? input.value : null}
        onChange={(value) => input.onChange(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" size="small">
                {icon}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  </div>
);

export const renderTimesInput = ({
  className,
  inputClass,
  input,
  label,
  meta: { touched, error },
  keyboard,
  ampm,
  size,
  color,
  variant,
  inputVariant,
  type,
  required,
  placeholder,
  icon,
}) => (
  <div className={className}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        className={inputClass}
        error={!!(touched && error)}
        label={required ? `${label  } *` : label}
        keyboard={keyboard}
        ampm={false}
        placeholder={placeholder}
        type={type}
        size={size}
        color={color}
        variant={variant}
        inputVariant={inputVariant}
        helperText={touched && error}
        fullWidth
        {...input}
        mask={[/\d/, /\d/, ":", /\d/, /\d/, " ", /a|p/i, "M"]}
        value={input.value ? input.value : null}
        onChange={(value) => input.onChange(value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" size="small">
                {icon}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </MuiPickersUtilsProvider>
  </div>
);

export const renderTextAreaInput = ({
  className,
  disabled,
  input,
  label,
  meta: { touched, error },
  size,
  color,
  variant,
  type,
  readonly,
  required,
  placeholder,
  rows,
  rowsMax,
}) => (
  <div className={className}>
    <TextField
      error={!!(touched && error)}
      label={required ? `${label  } *` : label}
      placeholder={placeholder || ""}
      type={type}
      size={size}
      color={color}
      variant={variant}
      helperText={touched && error}
      multiline
      rowsMax={rowsMax}
      rows={rows}
      fullWidth
      {...input}
      disabled={disabled ? (disabled === "true") : false}
      value={input.value}
      InputProps={{ readOnly: !!readonly }}
    />
  </div>
);

export const renderSelectInput = ({
  inputClass,
  className,
  disabled,
  input,
  value,
  label,
  meta: { touched, error },
  size,
  color,
  variant,
  type,
  children,
  required,
}) => (
  <div className={className}>
    <TextField
      className={inputClass}
      fullWidth
      select
      error={!!(touched && error)}
      label={required ? `${label  } *` : label}
      type={type}
      size={size}
      color={color}
      variant={variant}
      helperText={touched && error}
      {...input}
      value={input.value}
      disabled={disabled ? (disabled === "true") : false}
    >
      {children}
    </TextField>
  </div>
);

export const renderAsyncCreatableSelect = ({
  suffix,
  className,
  inputClass,
  input,
  label,
  meta: { touched, error },
  placeholder,
  size,
  color,
  required,
  isMulti,
  defaultValue,
  fetchOptions,
  initialOptions,
  isClearable,
  createOption,
  menuIsOpen,
  blankData,
}) => (
  <div className={className}>
    <AsyncCreatable
      error={!!(touched && error)}
      helperText={touched && error}
      {...input}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
      components={components}
      loadOptions={fetchOptions}
      defaultOptions={initialOptions || []}
      formatCreateLabel={createOption}
      placeholder={placeholder}
      size={size}
      color={color}
      onChange={(value) => input.onChange(value)}
      onBlur={() => input.onBlur(input.value)}
      selectedValue={defaultValue}
      value={input.value}
      isMulti={isMulti}
      noOptionsMessage={noOptionsMessage}
      isClearable={isClearable || false}
      menuIsOpen={menuIsOpen}
      className={touched && error ? `${inputClass  } invalid` : inputClass}
      InputProps={{
        endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
      }}
      textFieldProps={{
        label,
        required,
        InputLabelProps: {
          shrink: true,
        },
      }}
    />
    <div
      className={
        `MuiFormHelperText-contained ${  touched && error ? "Mui-error" : ""}`
      }
    >
      {touched ? error : ""}
    </div>
  </div>
);

export const renderCheckbox = ({
  className,
  Class,
  name,
  disabled,
  input,
  label,
  meta: { touched, error },
  size,
  color,
  checked,
  onChange,
  required,
}) => (
  <div className={className}>
    <FormControlLabel
      className={Class}
      error={!!(touched && error)}
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          name={name}
          size={size}
          color={color}
        />
      }
      label={required ? `${label  } *` : label}
      {...input}
      helperText={touched && error}
      value={input.value}
      disabled={disabled ? (disabled === "true") : false}
    />
  </div>
);

export const renderRadio = ({
  className,
  Class,
  name,
  disabled,
  input,
  label,
  meta: { touched, error },
  size,
  color,
  checked,
  onChange,
  required,
}) => (
  <div className={className}>
    <FormControlLabel
      className={Class}
      error={!!(touched && error)}
      control={
        <Radio
          checked={checked}
          onChange={onChange}
          name={name}
          size={size}
          color={color}
        />
      }
      label={required ? `${label  } *` : label}
      {...input}
      helperText={touched && error}
      value={input.value}
      disabled={disabled ? (disabled === "true") : false}
    />
  </div>
);

export const renderSwitch = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  checked,
  textPosition,
  className,
}) => (
  <div className={error || warning ? `${className  }invalid` : className}>
    <label className={`switch ${  textPosition}`}>
      <input
        type={type}
        {...input}
        checked={input.value}
        className={error || warning ? "form-control invalid" : "form-control"}
      />
      <span className="slide" />
      <span className="slide-text">{label}</span>
    </label>
    {(error && <div className="invalid-feedback">{error}</div>) ||
      (warning && <div className="invalid-feedback">{warning}</div>)}
  </div>
);

export const renderFileInput = ({
  id,
  className,
  btnClass,
  disabled,
  input,
  label,
  variant,
  meta: { touched, error },
  size,
  color,
  required,
  icon,
  hint,
}) => (
  <div className={className}>
    <label htmlFor={id} required className="file-upload">
      <input accept="image/*" id={id} type="file" />
      <Button
        variant={variant}
        size={size}
        color={color}
        className={btnClass}
        disabled={disabled ? (disabled === "true") : false}
      >
        {icon}
      </Button>

      <div className="up-text">
        {required ? `${label  } *` : label}
        <span>{hint} </span>
      </div>
    </label>
  </div>
);
