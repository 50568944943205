import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
	createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import {AuthApp} from './../checkAuthenticationHoc';
import Layout from '../components/Physio-User/theme/Layout';

const checkIsLogged = () => {};
const theme = createTheme({
  palette: {
    primary: {
      light: '#525151',
      main: '#767474',
      dark: '#918f8f',
      contrastText: '#fff',
    },
    secondary: {
      light: '#9efeff',
      main: '#69CBE1',
      dark: '#2e9aaf',
      contrastText: '#000',
    },
    white: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#000',
    },
    error: {
      light: '#ff7663',
      main: '#FF543D',
      dark: '#b23a2a',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: "'galanogrotesque', sans-serif",
    fontSize: 16,
    fontStyle: '400',
    fontDisplay: 'block',
  },
  tooltip: {
    backgroundColor: '#333',
    color: '#fff',
    fontSize: 11,
  },
});
export const PhysioUserRoute = ({ component: Component, ...rest }) => (
  // <MuiThemeProvider theme={theme}>
    <Route
      {...rest}
      render={(props) => {
        checkIsLogged();
        return true ? (
          // <Layout history={props.history}>
            <Component {...props} />
          // </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  // </MuiThemeProvider>
);
