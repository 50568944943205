import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';
import { useHistory, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { store } from './store/index';
import LinearProgress from '@material-ui/core/LinearProgress';
import { setAuthUserdata, signOut } from './reducers/Auth/action';
import { checkUserAuthentication } from '../src/utilities/api/auth';

/**
 * Hoc for Auth check
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Comment} component
 * @return {Comment}
 */
const checkAuthenticationHoc = (WrappedComponent, department) => (props) => {
  const state = store.getState();
  const isAuthenticated = state.userData.isAuthenticated;
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  let stay = localStorage.getItem('stay_logged_in');
  let accessToken = null;
  if (stay == 'True') {
    accessToken = localStorage.getItem('access_token');
  } else if (stay == 'False') {
    accessToken = sessionStorage.getItem('access_token');
  }

  useEffect(() => {
    if (!accessToken) {
      setIsAuth(false);
      setLoading(false);
    } else if (accessToken && isAuthenticated == false) {
      checkUserAuthentication(accessToken).then(
        (response) => {
          setIsAuth(true);
          store.dispatch(setAuthUserdata(response.user_profile));
        },
        (error) => {
          store.dispatch(signOut());
          setIsAuth(false);
          setLoading(false);
        },
      );
    } else {
      setIsAuth(true);
    }
  }, []);

  if (loading == true && isAuth == false) {
    return <LinearProgress />;
  } else if (loading == false && isAuth == false) {
    return <Redirect to="/" />;
  } else if (isAuth == true) {
    return (
      <>
        <WrappedComponent {...props} />
      </>
    );
  }
};

export const AuthApp = checkAuthenticationHoc;
