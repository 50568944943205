import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import {
  Box,
  Button,
  Fab,
  MenuItem,
  Snackbar,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {
  ArrowRightAlt,
  PanToolSharp,
  PhoneCallback,
  Mail,
} from '@material-ui/icons';
import {
  renderTextInput,
  renderCheckbox,
  renderSelectInput,
} from '../UiComponents/renderUtils';

import '../assets/css/login.scss';

// Own
import { login, request_password_reset } from '../utilities/api/apiCalls';
import { initializeRegistrationData } from '../utilities/localStorageFunctions';

import { withSnackbar } from '../SnackbarHOC';
import { setUserdata } from '../reducers/Auth/action';
import { makeStyles } from '@material-ui/core/styles';

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;
import { AuthApp } from '../checkAuthenticationHocLoggedIn';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    position: 'fixed',
  },
  media: {
    height: 140,
  },
});

const LogIn = (props) => {
  const [forget, setForget] = React.useState(false);
  const [department, setDepartment] = React.useState('');
  const [loader, setloader] = useState(false);
  const [email, setEmail] = React.useState('');
  const [stayLoggedIn, setStayLoggedIn] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [helperText, setHelperText] = React.useState('');
  const [logoName, setLogoName] = React.useState('ph');
  const [open, setOpen] = React.useState(false);
  const [alertType, setAlertType] = React.useState('');
  const [forgot_password_email, setForgotPasswordEmail] = React.useState('');

  const classes = useStyles();

  const handleChange = (event) => {
    setDepartment(event.target.value);
    setHelperText('');
    setLogoName('');
    if (event.target.value === 'administrator') {
      setHelperText('You chose Administrator');
      setLogoName('php');
    } else if (event.target.value === 'laboratory') {
      setHelperText('You chose Laboratory');
      setLogoName('phl');
    } else if (event.target.value === 'radiology') {
      setHelperText('You chose Radiology');
      setLogoName('phr');
    } else if (event.target.value === 'nurse') {
      setHelperText('You chose Nurse');
      setLogoName('phn');
    } else if (event.target.value === 'physiotherapy') {
      setHelperText('You chose Physiotherapy');
      setLogoName('phph');
    } else if (event.target.value === 'doctor') {
      setHelperText('You chose Doctor');
      setLogoName('phd');
    }
    setOpen(true);
    setAlertType('info');
  };

  const handleValidSubmit = (user_profile) => {
    if (user_profile.reset_password) {
      setHelperText('');
      setAlertType('warning');
      setHelperText('Your password needs to be reset. Redirecting...');
      window.location.href = `/#/password-reset/${user_profile.user}`;
      return;
    }
    setAlertType('success');

    if (department === 'administrator') {
      setHelperText(
        'You have successfully logged into Administrator department',
      );
      window.location.href = '/#/admin/dashboard';
    } else if (department === 'laboratory') {
      setHelperText('You have successfully logged into Laboratory department');
      window.location.href = '/#/lab/dashboard';
    } else if (department === 'radiology') {
      setHelperText('You have successfully logged into Radiology department');
      window.location.href = '/#/radio/dashboard';
    } else if (department === 'nurse') {
      setHelperText('You have successfully logged into Nurse department');
      window.location.href = '/#/nurse/dashboard';
    } else if (department === 'physiotherapy') {
      setHelperText(
        'You have successfully logged into Physiotherapy department',
      );
      if (user_profile.role === 'user') {
        window.location.href = '/#/physio-user/dashboard';
      } else {
        window.location.href = '/#/physio/dashboard';
      }
    } else if (department === 'doctor') {
      setHelperText('You have successfully logged into Doctor department');
      if (user_profile.role === 'user') {
        window.location.href = '/#/doctor-user/dashboard';
      } else {
        window.location.href = '/#/doctor/supervisor/dashboard';
      }
    } else {
      setHelperText('Please select a valid option from department list');
      setAlertType('error');
    }
  };

  const submit_forgot_password = async () => {
    const response = request_password_reset(forgot_password_email).then(
      (response) => {
        if (response.status === 200) {
          setAlertType('info');
          setHelperText(`Recovery email sent to ${forgot_password_email}`);
          setTimeout(() => {
            setForget(!forget);
          }, 3000);
        }
      },
    );

    return response;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setOpen(false);
    setOpen(true);

    if (email.length < 1) {
      props.snackbarShowMessage(`Email Address is required`, 'warning');
      return;
    }
    if (password.length < 1) {
      props.snackbarShowMessage(`"A Password is required`, 'warning');
      return;
    }

    if (department.length < 1) {
      props.snackbarShowMessage(`Please select a department`, 'warning');
      return;
    }
    setloader(true);

    const credentials = {
      email: email.toLowerCase(),
      password,
      department,
      stayLoggedIn,
    };
    await login(credentials)
      .then((response) => {
        if (response.status === 200) {
          setloader(false);
          if (stayLoggedIn) {
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);
            localStorage.setItem('stay_logged_in', 'True');
          } else {
            sessionStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('stay_logged_in', 'False');
          }

          props.setUserdata(response.data.user_profile, response.data.access_token);
          handleValidSubmit(response.data.user_profile);
        } else {
          console.log(Object.getOwnPropertyNames(response));
        }
      })
      .catch((error) => {
        setloader(false);
        console.log(error);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  return (
    <>
      <Box component="section" className="logBg">
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert onClose={handleClose} severity={alertType}>
            {helperText}
          </Alert>
        </Snackbar>
        <Box component="div" className="log-heading">
          <h1 className="log-text">
            Welcome to
            <br /> PoneaPractice
          </h1>
          <h2 className="log-subtext">Your patients await!</h2>
        </Box>
        <div className={`log-box ${forget ? 'leave' : 'arrive'}`}>
          <div className="log-logo">
            <img src={`${logoName}-logo.png`} alt="" />
          </div>
          <div className="log-body">
            <form autoComplete="off" onSubmit={handleSubmit}>
              <Field
                name="name"
                component={renderTextInput}
                type="text"
                label="Email"
                color="primary"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
                validate={[required({ message: 'Name is required.' })]}
                className="form-group"
              />
              <Field
                name="password"
                component={renderTextInput}
                type="Password"
                label="Password"
                color="primary"
                value={password}
                required
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                validate={[required({ message: 'Incorrect entry.' })]}
                className="form-group"
              />
              <Field
                name="country"
                component={renderSelectInput}
                label="Department"
                color="primary"
                value={department}
                onChange={handleChange}
                required
                validate={[required({ message: 'Incorrect entry.' })]}
                className="form-group">
                <MenuItem value="administrator">Administrator</MenuItem>
                <MenuItem value="laboratory">Laboratory</MenuItem>
                <MenuItem value="physiotherapy">Physiotherapy</MenuItem>
                <MenuItem value="doctor">Doctor</MenuItem>
              </Field>
              <div className="row justify-content-between align-items-center log-check">
                <div className="col-auto">
                  <Field
                    name="check"
                    component={renderCheckbox}
                    color="secondary"
                    onChange={(e) => {
                      setStayLoggedIn(!stayLoggedIn);
                    }}
                    Class="mr-0"
                    label="Stay login"
                  />
                </div>
                <div className="col-auto">
                  <Button onClick={() => setForget(!forget)}>
                    Forgot Password
                  </Button>
                </div>
              </div>
              <div className="row justify-content-between align-items-center log-check">
                <div className="col-auto">
                  <Fab variant="extended" type="submit">
                    Login{' '}
                    {loader == true ? (
                      <CircularProgress
                        className="ml-3"
                        color={'inherit'}
                        size={15}
                      />
                    ) : (
                      <ArrowRightAlt className="ml-3" />
                    )}
                  </Fab>
                </div>
                <div className="col-auto">
                  <Button
                    onClick={(event) => {
                      initializeRegistrationData();
                      window.location.href = '/#/registration';
                    }}>
                    Registration
                  </Button>
                </div>
              </div>
              <hr />
              <div style={{ color: 'black' }}>
                <h4>Need Assistance?</h4>
                <div className="col-auto">
                  <h4
                    className="align-items-center"
                    style={{ display: 'flex' }}>
                    <PhoneCallback fontSize="small" />
                    <span>+254 111 013 900</span>
                  </h4>
                </div>

                <div className="col-auto">
                  <span
                    className="align-items-center"
                    style={{ display: 'flex' }}>
                    <Mail fontSize="small" />
                    <span>support-ke@poneahealth.com</span>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className={`log-box ${forget ? 'arrive' : 'leave'}`}>
          <div className="log-logo">
            <img src="php-logo.png" alt="" />
          </div>
          <div className="log-body">
            <div className="log-heading">
              Forgot Password
              <span>Enter the email address to reset your password</span>
            </div>
            <form autoComplete="off">
              <Field
                name="forgot_password_email"
                component={renderTextInput}
                type="email"
                label="Email"
                color="primary"
                onChange={(e) => {
                  setForgotPasswordEmail(e.target.value);
                }}
                value={forgot_password_email}
                required
                validate={[required({ message: 'Email is required.' })]}
                className="form-group"
              />
              <div className="row justify-content-between align-items-center log-check">
                <div className="col-auto">
                  <Fab variant="extended" onMouseUp={submit_forgot_password}>
                    Submit <ArrowRightAlt className="ml-3" />
                  </Fab>
                </div>
                <div className="col-auto">
                  <Button onClick={() => setForget(!forget)}>
                    Return to Login
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = (state, props) => ({
  initialValues: {
    name: '',
    // country: 'admin'
  },
});
export default connect(mapStateToProps, {
  setUserdata,
})(reduxForm({ form: 'user-create-form' })(withSnackbar(AuthApp(LogIn))));
