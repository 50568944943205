import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';
import { useHistory, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { store } from './store/index';
import LinearProgress from '@material-ui/core/LinearProgress';
import { setAuthUserdata } from './reducers/Auth/action';
import { checkUserAuthentication, signOut } from './utilities/api/auth';

/**
 * Hoc for Auth check
 * @author Sourav Ghosh <sourav.ghosh@navsoft.in>
 * @param {Comment} component
 * @return {Comment}
 */
const checkAuthenticationHocLoggedIn = (WrappedComponent) => (props) => {
  const state = store.getState();
  const history = useHistory();
  const isAuthenticated = state.userData.isAuthenticated;
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  let stay = localStorage.getItem('stay_logged_in');
  let accessToken = null;
  if (stay == 'True') {
    accessToken = localStorage.getItem('access_token');
  } else if (stay == 'False') {
    console.log(accessToken);
    accessToken = sessionStorage.getItem('access_token');
  }

  const handleValidSubmit = (user_profile) => {
    let department = user_profile.department;

    if (user_profile.reset_password) {
      props.snackbarShowMessage('');
      props.snackbarShowMessage('warning');
      props.snackbarShowMessage(
        'Your password needs to be reset. Redirecting...',
      );
      window.location.href = `/#/password-reset/${user_profile.user}`;
      return;
    }
    props.snackbarShowMessage('success');

    if (department === 'administrator') {
      props.snackbarShowMessage(
        'You have successfully logged into Administrator department',
      );
      window.location.href = '/#/admin/dashboard';
    } else if (department === 'laboratory') {
      props.snackbarShowMessage(
        'You have successfully logged into Laboratory department',
      );
      window.location.href = '/#/lab/dashboard';
    } else if (department === 'radiology') {
      props.snackbarShowMessage(
        'You have successfully logged into Radiology department',
      );
      window.location.href = '/#/radio/dashboard';
    } else if (department === 'nurse') {
      props.snackbarShowMessage(
        'You have successfully logged into Nurse department',
      );
      window.location.href = '/#/nurse/dashboard';
    } else if (department === 'physiotherapy') {
      props.snackbarShowMessage(
        'You have successfully logged into Physiotherapy department',
      );
      console.log(user_profile.role);
      if (user_profile.role === 'user') {
        window.location.href = '/#/physio-user/dashboard';
      } else {
        window.location.href = '/#/physio/dashboard';
      }
    } else if (department === 'doctor') {
      if (user_profile.role === 'user') {
        window.location.href = '/#/doctor-user/dashboard';
      } else {
        window.location.href = '/#/doctor/supervisor/dashboard';
      }
    } else {
      props.snackbarShowMessage(
        'Please select a valid option from department list',
      );
      props.snackbarShowMessage('error');
    }
  };

  useEffect(() => {
    if (!accessToken) {
      setIsAuth(false);
      setLoading(false);
    } else if (accessToken && isAuthenticated == false) {
      checkUserAuthentication(accessToken).then(
        (response) => {
          console.log(
            'checkUserAuthentication',
            response.user_profile.department,
          );
          handleValidSubmit(response.user_profile);
          store.dispatch(setAuthUserdata(response.user_profile));
        },
        (error) => {
          // store.dispatch(signOut());
          setIsAuth(false);
          setLoading(false);
          setRedirect(true);
        },
      );
    } else {
      handleValidSubmit(state.userData.userData);
    }
  }, []);

  if (loading == true && isAuth == false) {
    return <LinearProgress />;
  } else if (loading == false && isAuth == false) {
    return (
      <>
        <WrappedComponent {...props} />
      </>
    );
  } else if (isAuth == true) {
    return (
      <>
        <WrappedComponent {...props} />
      </>
    );
  }
};

export const AuthApp = checkAuthenticationHocLoggedIn;
