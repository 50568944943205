//   export const API_URL_PRACTICE = 'http://00ed3d40fbce.ngrok.io/api';
// TODO: give some authority to making a user or look into the process of creating an admin
export const baseURL = process.env.REACT_APP_API_URL;
export const auth_url = process.env.REACT_APP_CONCIERGE_AUTHENTICATE_URL;
export const auth_body = JSON.parse(process.env.REACT_APP_CONCIERGE_AUTH_DATA);
export const auth_url_callcenter = process.env.REACT_APP_CALLCENTER_AUTH_URL;
export const auth_body_callcenter = JSON.parse(
  process.env.REACT_APP_CALLCENTER_AUTH_BODY,
);
export const conciergeSUrl = process.env.REACT_APP_CONCIERGE_SERVICE;
export const conciergePUrl = process.env.REACT_APP_CONCIERGE_PACKAGES;
export const API_URL = process.env.REACT_APP_CALLCENTER_URL;

export default {
  COLUMN_URL: `${API_URL}/basics/`,
  LOGIN_URL: `${API_URL}/v1/auth/login/`,
  COUNTRY_URL: `${API_URL}/v1/countries`,
  STATE_URL: `${API_URL}/v1/states`,
  CITY_URL: `${API_URL}/v1/cities`,
  DOCTOR_URL: `${API_URL}/doctors/`,
  country: `${API_URL}/common/country/`,
  state: `${API_URL}/common/state/`,
  COMMON_API: `${API_URL}/common/`,
  COMMON_URL: `${API_URL}/common/`,
  // callcenter api <wcr>
  // MESSAGE_URL: `${API_URL}/messaging/v1/sms-send/`,
};
