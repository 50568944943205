import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { NavLink } from 'react-router-dom';
import { useHistory, Redirect } from 'react-router-dom';
import { createTheme } from '@material-ui/core/styles';
import {   
    Typography,
  } from '@material-ui/core';
import {
  ImportContacts,
  Colorize,
  ExpandLess,
  ExpandMore
} from '@material-ui/icons';
import { get_local_user_profile } from "../../utilities/api/apiCalls"

import {
  Dashboard,
  Dns,
  DeviceHubOutlined,
  DateRange,
} from "@material-ui/icons";

const theme = createTheme()
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    marginTop: 10,
    color: '#323F4A',
    padding: '0px',
    position: 'relative',

    borderBottomColor: '#ccc',
    backgroundColor: '#F9F5F5',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function NestedList() {
 
const history = useHistory();
  const custom_classes = useStyles();
  let pathname = history.location.pathname;
  const [open, setOpen] = React.useState(pathname == '/lab/submitted-appointments' || pathname =='/lab/pending-appointments'); 
  const [is_vaccine_facility, setIsVaccineFacility] = React.useState(false) 

	
  React.useEffect(()=>{
	  setIsVaccineFacility(get_local_user_profile().kepsa_vaccine_facility)
  })
  const handleClick = () => {
    setOpen(!open);   
  };


  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={[custom_classes.root, 'admin_theme']}>
      <NavLink
	  	key={1}
        style={{ borderBottomWidth: '1px' }}
        exact
        activeClassName="activeClass"
        to="/lab/dashboard">
        <ListItem button>
          <ListItemIcon>
            <Dashboard fontSize="small" />
          </ListItemIcon>

          <ListItemText primary="Dashboard" />
        </ListItem>
      </NavLink>

      <NavLink
	  	key={2}
        style={{ borderBottomWidth: '1px' }}
        exact
        activeClassName="activeClass"
        to="/lab/work-orders">
        <ListItem button>
          <ListItemIcon>
            <Dns  fontSize="small"/>
          </ListItemIcon>

          <ListItemText primary={'Work Orders'}  />
        </ListItem>
      </NavLink>
    
      <ListItem key={3} button onClick={handleClick}>
        <ListItemIcon>
          <ImportContacts fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Reports" />
        {open ? <ExpandLess style={{ color: '#f0625c' }} /> : <ExpandMore  style={{ color: '#f0625c' }}/>}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NavLink  
             key={4}
            style={{ borderBottomWidth: '1px' }}
            exact
            activeClassName="activeClass"
            to="/lab/pending-appointments">
            <ListItem button>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Pending" />
            </ListItem>
          </NavLink>
          <NavLink
		  	key={5}
            style={{ borderBottomWidth: '1px' }}
            exact
            activeClassName="activeClass"
            to="/lab/submitted-appointments">
            <ListItem button>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Submitted" />
            </ListItem>
          </NavLink>
         
        </List>
      </Collapse>  
		{
			is_vaccine_facility && (
				<NavLink
					key={6}
					style={{ borderBottomWidth: '1px' }}
					exact
					activeClassName="activeClass"
					to="/lab/vaccinations">
					<ListItem button>
					<ListItemIcon>
						<Colorize  fontSize="small"/>
					</ListItemIcon>
					<ListItemText primary={'Vaccinations'}  />
					</ListItem>
				</NavLink>
			)
		}
	  

    </List>
  );
}
