import React from 'react';
import {makeStyles,Link } from '@material-ui/core';

const useStyles = makeStyles({
  footer: {position: 'fixed',zIndex: 10,right:'0',bottom:0, display:'flex', alignItems:'center', color:'#fff', padding: '0.5rem 0.5rem 0.5rem 3.125rem',fontWeight:300,overflow: 'hidden',
    '&:before':{content:`''`,position:'absolute',backgroundColor: '#0D1424',borderTopLeftRadius: '1rem',transform: 'skewX(-40deg)',width:'100%',height:'100%',right:'-1.25rem',top:0,},
  	'& a':{display:'flex', alignItems:'center',color:'#fff',position:'relative',zIndex:2,
  		'& img':{maxHeight:'1.875rem',width:'auto',marginLeft:'0.5rem',}
  	}
	},
  footerGap: {height:'2.5rem'}
});
export default function Footer(){
	const classes = useStyles();
  return (
     <>
     <div class="footer-hack">
     <footer className={classes.footer}>     		
     		<Link>
     			POWERED BY
          <img src="php-logo.png" alt=""/>
        </Link>
     	</footer>
      <div className={classes.footerGap} />
     </div>     
     </>
  )
};


