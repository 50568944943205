import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
	createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import { AuthApp } from './../checkAuthenticationHoc';


const checkIsLogged = () => {
  // TODO: if user is logged in, redirect them to their home screen
  if (localStorage.getItem('access_token') === '1') {
    return false;
  }
  return true;
};

const theme = createTheme({
  palette: {
    primary: {
      light: '#525151',
      main: '#767474',
      dark: '#918f8f',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f3817c',
      main: '#F0625C',
      dark: '#a84440',
      contrastText: '#fff',
    },
    white: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#fff',
    },
    error: {
      light: '#ff7663',
      main: '#FF543D',
      dark: '#b23a2a',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: "'galanogrotesque', sans-serif",
    fontSize: 16,
    fontStyle: '400',
    fontDisplay: 'block',
  },
  tooltip: {
    backgroundColor: '#333',
    color: '#fff',
    fontSize: 11,
  },
});

export const PrivateRoute = ({ component: Component, ...rest }) => (
  // <MuiThemeProvider theme={theme}>
    <Route
      {...rest}
      render={(props) =>  
            <Component {...props} /> 
      }
    />
  // </MuiThemeProvider>
);
