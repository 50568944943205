// TODO: switch from DateFnsUtils to moments.js
import moment from 'moment'
export const time_string = (raw_time) => {
  const time = new Date(raw_time);

  let hours_as_int = parseInt(time.getHours());
  const ampm = hours_as_int <= 12 ? 'AM' : 'PM';
  hours_as_int = hours_as_int > 12 ? hours_as_int - 12 : hours_as_int;
  const hours =
    hours_as_int === 0
      ? '12'
      : hours_as_int >= 10
      ? hours_as_int.toString()
      : `0${hours_as_int.toString()}`;
  const minutes_as_int = parseInt(time.getMinutes());
  const minutes =
    minutes_as_int > 10
      ? minutes_as_int.toString()
      : `0${minutes_as_int.toString()}`;

  return `${hours}:${minutes} ${ampm}`;
};

export const time_string_django = (raw_time) => {
  raw_time = `December 17, 1995 ${raw_time}`;
  return time_string(raw_time);
};

export const date_string = (raw_date) => {
  const date = new Date(raw_date);
  return date.toDateString();
};

export const date_time_string = (raw_date_time) => {
  const date = date_string(raw_date_time);
  const time = time_string(raw_date_time);
  return `${date} at ${time}`;
};

export const get_django_time_format = (raw_time) => time_string(raw_time);

export const get_django_date_format = (raw_date) => {
  const date = new Date(raw_date);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const formatted_date = `${year}-${month}-${day}`;

  return formatted_date;
};

export const get_django_date_format2 = (raw_date) => {
  const date = new Date(raw_date); 
  const formatted_date = moment(date).format('YYYY-MM-DD')

  return formatted_date;
};
