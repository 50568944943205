import React, { lazy, Suspense } from 'react';
import Header from './Header';
import UserNav from './../../../UiComponents/theme/Nav';
import { HashRouter, Router, Route, Switch } from 'react-router-dom';
import {
	createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { AuthApp } from '../../../checkAuthenticationHoc';

const PhysioUserHome = lazy(() => import('../dashboard'));
const PhysioUserWorkOrders = lazy(() => import('../PhysioUserWorkorder'));
// const PhysioWorkOrders = lazy(() => import('../../Physio/physioworkorders'));
const PhysioUserAssigned = lazy(() => import('../../../UiComponents/UserAssigned'));
const PhysioData = lazy(() => import('../../Doctor-User/DoctorUserDetails'));

const PhysioUserStartConsult = lazy(() => import('../StartConsult'));
import { get_local_user_profile } from '../../../utilities/api/apiCalls';
import '../../../assets/css/layout.scss';

const theme = createTheme({
  palette: {
    primary: {
      light: '#525151',
      main: '#767474',
      dark: '#918f8f',
      contrastText: '#fff',
    },
    secondary: {
      light: '#9efeff',
      main: '#69CBE1',
      dark: '#2e9aaf',
      contrastText: '#000',
    },
    white: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#000',
    },
    error: {
      light: '#ff7663',
      main: '#FF543D',
      dark: '#b23a2a',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: "'galanogrotesque', sans-serif",
    fontSize: 16,
    fontStyle: '400',
    fontDisplay: 'block',
  },
  tooltip: {
    backgroundColor: '#333',
    color: '#fff',
    fontSize: 11,
  },
});

const Layout = (props) => {
  const department = get_local_user_profile().department;
  const role = get_local_user_profile().role;
  console.log({role})
  console.log(typeof SupervisorNav);
  return (
    <>
      <MuiThemeProvider theme={theme}>
        <Header history={props.history} />
        <UserNav role={role} />        

        <div className="contain-part physio-padding">
          <Suspense fallback={<LinearProgress />}>
            <Switch>
              <Route
                path="/physio-user/dashboard/"
                component={PhysioUserHome}
              />

              <Route
                path="/physio-user/work-orders/"
                component={PhysioUserWorkOrders}
              />
              {/* {role && role === 'supervisor' && (
                <Route
                  path="/physio/work-orders"
                  component={PhysioWorkOrders}
                />
              )} */}

              <Route
                path="/physio-user/assigned/"
                component={PhysioUserAssigned}
              />

              <Route path="/physio-user/details/:id" component={PhysioData} />

              <Route
                path="/physio-user/start-consult/:id"
                component={PhysioUserStartConsult}
              />
            </Switch>
          </Suspense>
        </div>
        {/* <Footer /> */}
      </MuiThemeProvider>
    </>
  );
};
export default AuthApp(Layout, 'PhysioUser');
